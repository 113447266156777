import {
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  FIND_USER_REQUEST,
  FIND_USER_FAIL,
  FIND_USER_SUCCESS,
  FIND_USER_RESET,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAIL,
  CANCEL_SUBSCRIPTION_RESET,
} from "../Constants/UserContants";

// LOGIN
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

// ALL USER
export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case USER_LIST_RESET:
      return { users: [] };
    default:
      return state;
  }
};

export const findUserReducer = (state = { }, action) => {
  switch (action.type) {
    case FIND_USER_REQUEST:
      return { loading: true };
    case FIND_USER_SUCCESS:
      return { loading: false, success:true, payload: action.payload };
    case FIND_USER_FAIL:
      return { loading: false,success:false, payload: action.payload };
    case FIND_USER_RESET:
      return { payload: {} };
    default:
      return state;
  }
};

export const cancelSubscriptionReducer = (state = { }, action) => {
  switch (action.type) {
    case CANCEL_SUBSCRIPTION_REQUEST:
      return { loading: true };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return { loading: false, success:true};
    case CANCEL_SUBSCRIPTION_FAIL:
      return { loading: false,success:false,message:action.message};
    case CANCEL_SUBSCRIPTION_RESET:
      return {};
    default:
      return state;
  }
};