export const CATALOG_LIST_REQUEST = "CATALOG_LIST_REQUEST";
export const CATALOG_LIST_SUCCESS = "CATALOG_LIST_SUCCESS";
export const CATALOG_LIST_FAIL = "CATALOG_LIST_FAIL";

export const CATALOG_EDIT_REQUEST = "CATALOG_EDIT_REQUEST";
export const CATALOG_EDIT_SUCCESS = "CATALOG_EDIT_SUCCESS";
export const CATALOG_EDIT_FAIL = "CATALOG_EDIT_FAIL";

export const CATALOG_DELETE_REQUEST = "CATALOG_DELETE_REQUEST";
export const CATALOG_DELETE_SUCCESS = "CATALOG_DELETE_SUCCESS";
export const CATALOG_DELETE_FAIL = "CATALOG_DELETE_FAIL";

export const CATALOG_CREATE_REQUEST = "CATALOG_CREATE_REQUEST";
export const CATALOG_CREATE_SUCCESS = "CATALOG_CREATE_SUCCESS";
export const CATALOG_CREATE_FAIL = "CATALOG_CREATE_FAIL";
export const CATALOG_CREATE_RESET = "CATALOG_CREATE_RESET";

export const CATALOG_UPDATE_REQUEST = "CATALOG_UPDATE_REQUEST";
export const CATALOG_UPDATE_SUCCESS = "CATALOG_UPDATE_SUCCESS";
export const CATALOG_UPDATE_FAIL = "CATALOG_UPDATE_FAIL";
export const CATALOG_UPDATE_RESET = "CATALOG_UPDATE_RESET";
