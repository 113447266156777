export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";


export const FIND_USER_REQUEST = "FIND_USER_REQUEST";
export const FIND_USER_SUCCESS = "FIND_USER_SUCCESS";
export const FIND_USER_FAIL = "FIND_USER_FAIL";
export const FIND_USER_RESET = "FIND_USER_RESET";

export const CANCEL_SUBSCRIPTION_REQUEST = "CANCEL_SUBSCRIPTION_REQUEST";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAIL = "CANCEL_SUBSCRIPTION_FAIL";
export const CANCEL_SUBSCRIPTION_RESET = "CANCEL_SUBSCRIPTION_RESET";