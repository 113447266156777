// import { useDispatch } from "react-redux";
import React from "react";
import { Link } from "react-router-dom";
import classes from "./Director.module.css";


const DirectorsTable = ({ directors, onDelete, onEdit}) => {
  // const dispatch=useDispatch();
  // const deletehandler = (id) => {
  //   if (window.confirm("Are you sure??")) {
  //     dispatch(deleteDirectors(id));
  //   }
  // };



  return (
    <div className="col-md-12 col-lg-8">
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th className="text-end">Action</th>
          </tr>
        </thead>
        {/* Table Data */}
        <tbody>
          {directors?.map((director) => (
            <tr key={director.name}>
              <td>
                <b>{director.name}</b>
              </td>

              <td className="text-end">
                <div className="dropdown">
                  <Link
                    to="#"
                    data-bs-toggle="dropdown"
                    className="btn btn-light"
                  >
                    <i className="fas fa-ellipsis-h"></i>
                  </Link>
                  <div className="dropdown-menu">
                    <div
                      className={classes.buttons +" dropdown-item"}
                      onClick={() => onEdit(director?.id)}
                    >
                      Edit info
                    </div>
                    <div className={classes.buttons +" dropdown-item text-danger"}  onClick={() => {onDelete(director.id)}}>
                      Delete
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DirectorsTable;
