import React from "react";
import Sidebar from "./../components/sidebar";
import Header from "./../components/Header";
import UserDetailComponent from "../components/users/UserDetailComponent";

const UsersScreen = ({match}) => {
  const email = match.params.email;

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <UserDetailComponent email={email} />
      </main>
    </>
  );
};

export default UsersScreen;
