import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../api/baseConfig";
import ReactPaginate from "react-paginate";
import { useCallback } from "react";

const ContactList = () => {
  const [contactInfo, setContactInfo] = useState({});
  const [page, setPage] = useState(1);

  const loadContactInfo = React.useCallback(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    axios
      .get(BASE_URL + "/api/contact/getall?page=" + page, {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        setContactInfo(response.data);
      });
  }, [page]);

  useEffect(() => {
    loadContactInfo();
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    axios
      .get(BASE_URL + "/api/package/getall/EN", {
        headers: { "x-access-token": token },
      })
      .then((response) => {
      });
  }, [loadContactInfo]);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const deleteHandler = useCallback(
    (id) => {
      const token = JSON.parse(localStorage.getItem("userInfo")).token;

      axios
        .put(
          BASE_URL + "/api/contact/removebyid/" + id,
          {},
          {
            headers: { "x-access-token": token },
          }
        )
        .then(() => {
          loadContactInfo();
        });
    },
    [loadContactInfo]
  );

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">User Name</th>
            <th scope="col">Details</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {contactInfo?.data?.length
            ? contactInfo.data.map((contact) => (
                <tr key={contact.id}>
                  <td>{contact.name}</td>
                  <td>
                    <Link
                      to={`/contact-detail/${contact.id}`}
                      className="btn btn-warning"
                    >
                      Contact Details
                    </Link>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => deleteHandler(contact.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next>"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={
          contactInfo.total !== 0 ? Math.ceil(contactInfo.total / 20) : 1
        }
        previousLabel="< previous"
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
        renderOnZeroPageCount={1}
        forcePage={page - 1}
      />
    </>
  );
};

export default ContactList;
