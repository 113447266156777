import Sidebar from "../components/sidebar";
import Header from "../components/Header";
import { useParams } from "react-router-dom";
import PartnerViewsMain from "../components/PartnerViews/PartnerViewsMain";

const PlatformsRefScreen = () => {
  const {id} = useParams();
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <PartnerViewsMain id={id} />
      </main>
    </>
  );
};

export default  PlatformsRefScreen;