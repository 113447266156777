import React, { useEffect } from "react";
import CreateDirectors from "./CreateDirectors";
import DirectorsTable from "./DirectorsTable";
import { useDispatch } from "react-redux";
import { editDirectors, listDirectors } from "../../Redux/Actions/DirectorActions";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../api/baseConfig";

const MainDirectors = (props) => {

  const dispatch = useDispatch();
  const directorId=props.directorId??null;
  const [directorsList, setDirectorsList] = useState([]);
  const [directorsPage, setDirectorsPage] = useState(null);
  const [directorForEdit, setDirectorForEdit] = useState(null);
  const [page,setPage] = useState(1);

  const getDirectorById = React.useCallback((id) => {
    setDirectorForEdit(directorsList.find(director => director.id===id))
  }, [directorsList])
  


  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const loadDirectors = React.useCallback(() => {
    
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    axios
      .get(BASE_URL + `/api/directors/getall?page=${page}`, {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        setDirectorsList(response.data.data)
        setDirectorsPage(response.data.totalPage)
      });
  }, [page])


  useEffect(() => {
    loadDirectors()
  }, [loadDirectors])

  useEffect(() => {
    if (directorId !== null) {
      dispatch(editDirectors(directorId));
    }
  }, [directorId, dispatch]);
  useEffect(() => {
    dispatch(listDirectors({page}));
  }, [dispatch,page]);

  const deleteHandler = React.useCallback((id) => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    axios
      .delete(BASE_URL + "/api/directors/delete/" + id, {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        loadDirectors()
      });
  }, [loadDirectors])

  
  const createHandler = React.useCallback((name, mainPicture) => {
    let formData = new FormData();
    formData.append("image", mainPicture);
    formData.append("name", name);
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    axios
      .post(BASE_URL + "/api/directors/add", formData, {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        loadDirectors()
      });
  }, [loadDirectors])

  const editHandler = React.useCallback((id, name, mainPicture) => {
    let formData = new FormData();
    formData.append("id", id);
    formData.append("image", mainPicture);
    formData.append("name", name);
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    axios
      .put(BASE_URL + `/api/Directors/update/${id}`, formData, {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        loadDirectors()
      });
  }, [loadDirectors])

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Directors</h2>
      </div>

      <div className="card shadow-sm">
        <div className="card-body">
          <div className="row">
            <CreateDirectors director={directorForEdit} onCreate={createHandler} onEdit={editHandler} setDirector={setDirectorForEdit}/>
            <DirectorsTable directors={directorsList ? directorsList:[]} onDelete={deleteHandler} onEdit={getDirectorById} />
          </div>
          <div className="d-flex justify-content-end">
              <ReactPaginate
              breakLabel="..."
              nextLabel="next>"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={directorsPage ? Math.ceil( directorsPage / 20):1}
              previousLabel="< previous"
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
              renderOnZeroPageCount={1}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainDirectors;
