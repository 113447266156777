import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../LoadingError/Error";
import Episode from "./Episode";
import { listEpisode } from "../../Redux/Actions/EpisodeActions";
import ReactPaginate from "react-paginate";

const MainEpisodes = ({ seasonId }) => {
  const dispatch = useDispatch();
  const episodeList = useSelector((state) => state.episodeList);
  const { error, episodes, totalPage } = episodeList;
  const episodeDelete = useSelector((state) => state.episodeDelete);
  const { error: errorDelete, success: successDelete } = episodeDelete;
  const [page, setPage] = useState(1);
  useEffect(() => {
    dispatch(listEpisode(seasonId, "AZ", page));
    // dispatch(listSeason(tvshowId))
  }, [dispatch, successDelete, seasonId, page]);
  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };


  return (
    <section className="content-main">
      <div className="content-header">
          <Link to={`/series/`} className="btn btn-danger">
            Back to Series
          </Link>
        <h2 className="content-title">Episodes</h2>
          <Link to={`/addEpisode/${seasonId}`} className="btn btn-primary">
            Create new Episode
          </Link>
      </div>

      <div className="card mb-4 shadow-sm">
        <header className="card-header bg-white ">
          <div className="row gx-3 py-3">
          </div>
        </header>
        <div className="card-body">
          {errorDelete && (
            <Message variant="alert-danger">{errorDelete}</Message>
          )}
          {error ? (
            <Message variant="alert-danger">{error}</Message>
          ) : (
            <div className="row">
              {episodeList && episodeList?.episodes?.map((episode) => (
                <Episode seasonId={episode.season_id} episode={episode} key={episode.id} />
              ))}
            </div>
          )}
          {episodes && episodes.length !== 0 &&
            <div className="d-flex justify-content-end">
              <ReactPaginate
                breakLabel="..."
                nextLabel="next>"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={totalPage && totalPage !== 0 ? Math.ceil(totalPage / 10) : 1}
                previousLabel="< previous"
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                renderOnZeroPageCount={1}
              />
            </div>
          }
        </div>
      </div>
    </section>
  );
};

export default MainEpisodes;
