export const PLATFORM_REF_LIST_REQUEST = "PLATFORM_REF_LIST_REQUEST";
export const PLATFORM_REF_LIST_SUCCESS = "PLATFORM_REF_LIST_SUCCESS";
export const PLATFORM_REF_LIST_FAIL = "PLATFORM_REF_LIST_FAIL";

export const PLATFORM_REF_EDIT_REQUEST = "PLATFORM_REF_EDIT_REQUEST";
export const PLATFORM_REF_EDIT_SUCCESS = "PLATFORM_REF_EDIT_SUCCESS";
export const PLATFORM_REF_EDIT_FAIL = "PLATFORM_REF_EDIT_FAIL";

export const PLATFORM_REF_DELETE_REQUEST = "PLATFORM_REF_DELETE_REQUEST";
export const PLATFORM_REF_DELETE_SUCCESS = "PLATFORM_REF_DELETE_SUCCESS";
export const PLATFORM_REF_DELETE_FAIL = "PLATFORM_REF_DELETE_FAIL";

export const PLATFORM_REF_CREATE_REQUEST = "PLATFORM_REF_CREATE_REQUEST";
export const PLATFORM_REF_CREATE_SUCCESS = "PLATFORM_REF_CREATE_SUCCESS";
export const PLATFORM_REF_CREATE_FAIL = "PLATFORM_REF_CREATE_FAIL";
export const PLATFORM_REF_CREATE_RESET = "PLATFORM_REF_CREATE_RESET";

export const PLATFORM_REF_UPDATE_REQUEST = "PLATFORM_REF_UPDATE_REQUEST";
export const PLATFORM_REF_UPDATE_SUCCESS = "PLATFORM_REF_UPDATE_SUCCESS";
export const PLATFORM_REF_UPDATE_FAIL = "PLATFORM_REF_UPDATE_FAIL";
export const PLATFORM_REF_UPDATE_RESET = "PLATFORM_REF_UPDATE_RESET";
