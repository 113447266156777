import React from 'react'
import { Link } from 'react-router-dom'
import PaymentsList from './PaymentsList'

function PaymentsMain() {

    const [isFiltered, setIsFiltered] = React.useState(false);
    const [filterInfo, setFilterInfo] = React.useState({card:"",id:""})

    const card = React.useRef()
    const oderoId = React.useRef()


    React.useEffect(() => {
        if(filterInfo.card === "" && filterInfo.id === ""){
            setIsFiltered(false)
        }
    },[filterInfo])

    const filterInfoHandler = React.useCallback(() => {
        setIsFiltered(true)
        setFilterInfo({
            'card' : card.current.value,
            'id' : oderoId.current.value,
        })
    },[])

  return (
    <section className="content-main">
            <div className="content-header">
                <h2 className="content-title">Payments List</h2>
                <Link to='/add-giftcard' />
            </div>

            <div className="card mb-4 shadow-sm">
                <header className="card-header bg-white">
                    <div className="row gx-3 py-3">
                        <div className="col-lg-2 col-6 col-md-3 d-flex gap-3">
                            <div className="col-auto">
                                <label className="" htmlFor="autoSizingInput">card</label>
                                <input onChange={filterInfoHandler} ref={card} type="text" className="form-control" id="autoSizingInput" placeholder="card..." />
                            </div>
                            <div className="col-auto">
                                <label className="" htmlFor="autoSizingInput">odero id</label>
                                <input onChange={filterInfoHandler} ref={oderoId} type="text" className="form-control" id="autoSizingInput" placeholder="id..." />
                            </div>
                        </div>
                    </div>
                </header>
                <div className="card-body">
                    <div className="table-responsive">
                        <PaymentsList  isFiltered={isFiltered} filterInfo={filterInfo}/>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default PaymentsMain