import React, { useState} from "react";
import Tabs from "react-bootstrap/esm/Tabs";
import Tab from "react-bootstrap/Tab";
import { toast } from "react-toastify";
import Toast from "../LoadingError/Toast";
import axios from "axios";
import { BASE_URL } from "../../api/baseConfig";
import { IContentTypeEdit } from "./MainContentType";
import { IContentTypeCreate } from "./MainContentType";
import { langTypes } from "@/types";
const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};
type language = {name: string, id: number}
const languages: language[] = [{name: "AZ", id: 1}, {name: "EN", id: 2}, {name: "TR", id: 3}, {name: "RU", id: 4}]

const initialLangNames = [
  {name: "", lang_code: "AZ"},
  {name: "", lang_code: "EN"},
  {name: "", lang_code: "TR"},
  {name: "", lang_code: "RU"},
]

interface ICreateContentTypeProps {
  contentType: IContentTypeEdit | null | IContentTypeCreate
  onCancelEdit: () => void
  loadContentTypes: (page: number, lang: langTypes) => void
  page: number
}

const CreateContentType = (props: ICreateContentTypeProps) => {

  const [slugName,setSlugName] = useState("")
  const [langNames, setLangNames] = useState(initialLangNames);
  const token = JSON.parse(localStorage.getItem("userInfo")!).token;

  const createContentType = React.useCallback((contentType: IContentTypeCreate) => {
    axios
      .post(BASE_URL + "/api/contentType/add", contentType, {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        props.loadContentTypes(props.page, 'AZ');
        toast.success("ContentType created successfully", ToastObjects);
      });

  }, [props, token]);
  const editContentType = React.useCallback((contentType: IContentTypeEdit) => {
    axios
      .put(BASE_URL + "/api/contentType/update/" + contentType.id, contentType, {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        props.loadContentTypes(props.page, 'AZ');
        toast.success("ContentType edited successfully", ToastObjects);
        props.onCancelEdit()
      });

  }, [props, token]);
  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (props.contentType && 'id' in props.contentType) {
      editContentType({id: props.contentType.id, language: langNames, slug: slugName})
      
    } else {
      createContentType({language: langNames, slug: slugName})
    }
    setLangNames(initialLangNames);
    setSlugName("")
    }

  
  React.useEffect(() => {
    if (props.contentType) {
      setLangNames(props.contentType.language);
      setSlugName(props.contentType.slug);
    } else {
      setLangNames(initialLangNames);
      setSlugName("");
    }
  }, [props.contentType])

  const langNameChangeHandler = React.useCallback((language: language, newValue: string) => {
    setLangNames((prev) => {
      const newLangNames = [...prev];
      const langCode = language.name;
      const langIndex = newLangNames.findIndex((l) => l.lang_code === langCode);
      const newLangValue = {...newLangNames[langIndex]};
      newLangValue.name = newValue;
      newLangNames[langIndex] = newLangValue;
      return newLangNames
    });
  }, [])

  return (
    <>
    <Toast />
    <div className="col-md-12 col-lg-4">
      <form onSubmit={submitHandler}>
        <Tabs>
          {languages &&
            languages.map((l, index) => (
              <Tab eventKey={l.name} title={l.name} key={`movie-tab-${l.id}`}>
                <div className="mb-4">
                  <label
                    htmlFor={`product_title-${l.id}`}
                    className="form-label"
                  >
                    contentType title {l.name}
                  </label>
                  <input
                    type="text"
                    placeholder="Type here"
                    className="form-control"
                    id={`product_title-${l.id}`}
                    name="name"
                    required
                    // value={props.contentType ? props.contentType.name : createData.newName}
                    value={langNames[index].name}
                    onChange={e => langNameChangeHandler(l, e.target.value)}
                  />
                </div>
              </Tab>
            ))}
        </Tabs>
        <div className="mb-4">
          <label htmlFor="slug">Slug</label>
          <input className="form-control"
            placeholder="slug" id="slug" name="slug" value={slugName}
            required
            onChange={e=>setSlugName(e.target.value)}
            />
        </div>
        <div className="d-grid">
          {props.contentType?.language? (
            <>
            <button className="btn btn-warning py-3 mb-3" type="submit">Edit ContentType</button>
            <button type="button" onClick={props.onCancelEdit} className="btn btn-secondary py-3">Cancel</button>

            </>
          

          ):(
            <button type="submit" className="btn btn-primary py-3">Create ContentType</button>
          )}
        </div>
      </form>
    </div>
  </>
  );
};

export default CreateContentType;
