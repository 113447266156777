import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Message from "../LoadingError/Error";
import ReactPaginate from "react-paginate";
import Loading from "../LoadingError/Loading";

import {
  listPartners,PayTopartner,getAllPayment
} from "../../Redux/Actions/PartnerActions";
const PartnerList = () => {
  const dispatch = useDispatch();
  const { partners, data, total,loading } = useSelector((state) => state.partnerList);

  const [list, setList] = useState([]);

  const [id, setId] = useState('');
  const [filter_id, setFilterId] = useState('');
  const [cash, setcash] = useState(0);
  const [paymentlist, setPaymentList] = useState(null);


  const payToPartner = useSelector((state) => state.payToPartner);

  const { error, message } = payToPartner;

  const[page,setPage]=useState(1);


  useEffect(() => {
    dispatch(listPartners());
  }, [dispatch]);

  useEffect(() => {
    setList([...partners]);
  }, [partners]);

  useEffect(() => {
   setPaymentList(data);
  }, [data])

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  useEffect(() => {
    dispatch(getAllPayment(page,filter_id));
   }, [dispatch,page, filter_id]);

  //  useEffect(()=>{
  //   setTimeout(() => {
  //     console.log(page, filter_id, data)
  //     dispatch(getAllPayment(page,filter_id));
  //     setPaymentList(data);
  //     setPage(1)
  //   }, 1000);
  //  },[filter_id, data, dispatch, page])


  const submitHandler=(e)=>{
    e.preventDefault();
   dispatch(
        PayTopartner(id,parseInt(cash))
    ).then(x=>{
      setFilterId('')
      dispatch(getAllPayment(page,filter_id));
      setPaymentList(data);
      setPage(1)
    })

  }

  return (
      <section>
               <div className="card-body">
                    <div className="table-responsive">
        <div className="container d-flex">
            <div className="col-6">
          <form onSubmit={submitHandler}>
            <label>Partnyor</label>
  <select className="form-control" onChange={e=>setId(e.target.value)}>
    <option>Select...</option>
    {
    list.length>0 &&
    list.map((partner)=>(
    <option key={partner._id} value={partner._id}>{partner.username}</option>
    ))
    }
  </select>
  <label>Miqdar</label>
  <input type="number" className="form-control" defaultValue="0" onChange={e=>setcash(e.target.value)} />
  <input value="Ödə" type="submit" className="btn btn-success" />
  </form>
  </div>
  <div className="col-6">
  {error && <Message variant="alert-danger">{error}</Message>}
  {message && <Message variant="alert-success">{message}</Message>}
  </div>

  </div>
  <div >
    <label>Partnyor seçin</label>
  <select className="form-control" onChange={e=>setFilterId(e.target.value)}>
    <option value=''>Seç...</option>
    {
    list.length>0 &&
    list.map((partner)=>(
    <option key={partner._id} value={partner._id}>{partner.username}</option>
    ))
    }
  </select>
  { loading ? (
    <Loading/>
  ) : (
    <table className="table">
    <thead>
        <tr>
            <th scope="col">Partnyor</th>
            <th scope="col">Miqdar</th>
            <th scope="col">Ödəniş tarixi</th>
        </tr>
    </thead>
    {paymentlist?.length>0 && (
    <tbody>
        {paymentlist && paymentlist.map((item,index) => (
       <Tr item={item} key={index} />
        ))}
    </tbody>
    )}
  
  </table>
  )}

            <ReactPaginate
         breakLabel="..."
         nextLabel="next>"
         onPageChange={handlePageClick}
         pageRangeDisplayed={3}
         pageCount={total!==0 ? Math.ceil(total / 20) : 1}
         previousLabel="< previous"
         breakClassName={"page-item"}
         breakLinkClassName={"page-link"}
         containerClassName={"pagination"}
         pageClassName={"page-item"}
         pageLinkClassName={"page-link"}
         previousClassName={"page-item"}
         previousLinkClassName={"page-link"}
         nextClassName={"page-item"}
         nextLinkClassName={"page-link"}
         activeClassName={"active"}
         renderOnZeroPageCount={1}
         forcePage={page-1}
            />
            </div>
  </div>
</div>
  </section>

  );
};

export default PartnerList;

const Tr=({item})=>{
  const dateObj =  new Date(item.add_date)
  const date = `${dateObj.getFullYear()}.${dateObj.getMonth()+1}.${dateObj.getDate()}`
  return (
    <tr key={item.id}>
    <td>
        <span>{item.partner_name}</span>
    </td>
    <td>{item.cash} ₼</td>
    <td>{date}</td>
  </tr>
  )
}