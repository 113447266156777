import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listOderoSubscription } from "../../Redux/Actions/userActions";
import { Link } from "react-router-dom";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import ReactPaginate from "react-paginate";

const OderoSubscriptionMain = () => {
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userList);
  const [q, /* setQ */] = useState("")
  const { loading, error, users } = userList;
  const [allUsers, /* setAllUsers */] = useState(userList?.users);
  const [page, setPage] = useState(1);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };
  useEffect(() => {
    dispatch(listOderoSubscription({ page, q }));
  }, [dispatch, page, q]);


  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Customers</h2>
      </div>
      <div className="card mb-4">
        <header className="card-header">
          <div className="row gx-3">
            <div className="col-lg-4 col-md-6 me-auto">
              <input
                type="text"
                placeholder="Search..."
                className="form-control"
                // value={q}
                // onChange={e => setQ(e.target.value.toLowerCase())}
              />
            </div>
          </div>
        </header>

        <div className="card-body">
          {loading ? (
            <Loading />
          ) : error ? (
            <Message variant="alert-danger">{error}</Message>
          ) : (
            <>
                  {/* <button onClick={NoFilterBtn} style={{width:"100%",fontSize:"25px",marginBottom:"15px"}} className="btn btn-dark btn-lg">{users.total}</button> */}

<div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-3">

  {users?.data?.map((user) => (
    <div className="col" key={user.id}>
      <div className="card card-user shadow-sm">
        <div className="card-header">
       
          <Link to={`/user/${user.email}`}>
          <img
            className="img-md img-avatar"
            src="images/favicon.png"
            alt="User pic"
          />
             </Link>
      
       
        </div>
        <div className="card-body">
          <h5 className="card-title mt-5">{user.firstName}</h5>
          <div className="card-text text-muted">
            {user.claims && user.claims.includes("Admin") ? (
              <p className="m-0">Admin</p>
            ) : (
              <p className="m-0">Customer</p>
            )}

            <p>
              <a href={`mailto:${user.email}`}>{user.email}</a>
              <hr />
              <span>Phone: <a href={`tel:${user.phoneNumber}`}>{user.phoneNumber}</a> </span>
            </p>
            <hr />
            <b>növbəti ödəniş: {user.nextPaymentDate}</b>
          </div>
        </div>
      </div>
    </div>
  ))}
</div>
            
            </>
  
          )}

          {/* nav */}
          <div className="d-flex justify-content-end">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next>"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={allUsers?.total ? Math.ceil(allUsers.total / 20) : 1}
              previousLabel="< previous"
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
              renderOnZeroPageCount={1}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OderoSubscriptionMain;
