import React from 'react'
import { Link } from 'react-router-dom'
import SubscriptionList from './SubscriptionList'

function Subscription() {
  return (
    <section className="content-main">
            <div className="content-header">
                <h2 className="content-title">Subscription List</h2>
                <Link to='/add-package'>
                {/* <button className='btn btn-primary'>
                    Add Plan
                </button> */}
                </Link>
            </div>

            <div className="card mb-4 shadow-sm">
                <header className="card-header bg-white">
                    <div className="row gx-3 py-3">
                        <div className="col-lg-2 col-6 col-md-3">
                            
                        </div>
                        
                    </div>
                </header>
                <div className="card-body">
                    <div className="table-responsive">
                        <SubscriptionList />
                    </div>
                </div>
            </div>
        </section>
  )
}

export default Subscription