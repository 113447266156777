import axios from "axios";
import { useState } from "react";
import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../api/baseConfig";
import {
  listPartners,
  // deletePartner,
} from "../../Redux/Actions/PartnerActions";
const PartnerList = () => {
  const dispatch = useDispatch();
  const { partners } = useSelector((state) => state.partnerList);
  const [list, setList] = useState([]);
  const token = JSON.parse(localStorage.getItem("userInfo")).token;
  
  useEffect(() => {
    dispatch(listPartners());
  }, [dispatch]);

  useEffect(() => {
    setList([...partners]);
  }, [partners]);

  const deleteHandler = useCallback((id) => {
    axios
    .delete(`${BASE_URL}/api/partner/delete/${id}`, {
      headers: { "x-access-token": token },
    })
  },[token])

  const onDelete = async (id) => {
    if (window.confirm("Are you sure?")) {
      deleteHandler(id)
    }
    setList(list.filter((l) => l._id !== id));
  };
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Partner Name</th>
          <th scope="col">Delete</th>
          <th scope="col">Add Movie</th>
          <th scope="col">View count</th>
          <th scope="col">Filmlər</th>
        </tr>
      </thead>
      <tbody>
        {list.length > 0 &&
          list.map((partner) => (
            <tr key={partner._id}>
              <td>{partner.firstName} {partner.lastName}</td>
              <td>
                <button
                  onClick={() => onDelete(partner._id)}
                  className="btn btn-danger"
                >
                  Delete
                </button>
              </td>
              <td>
                <Link to="/addMovie" className="btn btn-warning">
                  New Movie
                </Link>
              </td>
              <td>
                <Link to={`/partnerViews/${partner._id}`}>
                  <button className="btn btn-info">Baxış sayı</button>
                </Link>
              </td>
              <td>
                <Link to={`/partnerScreen/${partner._id}`}>
                  <button className="btn btn-success">Filmləri</button>
                </Link>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default PartnerList;
