export const SEASON_LIST_REQUEST = "SEASON_LIST_REQUEST";
export const SEASON_LIST_SUCCESS = "SEASON_LIST_SUCCESS";
export const SEASON_LIST_FAIL = "SEASON_LIST_FAIL";

export const SEASON_EDIT_REQUEST = "SEASON_EDIT_REQUEST";
export const SEASON_EDIT_SUCCESS = "SEASON_EDIT_SUCCESS";
export const SEASON_EDIT_FAIL = "SEASON_EDIT_FAIL";

export const SEASON_DELETE_REQUEST = "SEASON_DELETE_REQUEST";
export const SEASON_DELETE_SUCCESS = "SEASON_DELETE_SUCCESS";
export const SEASON_DELETE_FAIL = "SEASON_DELETE_FAIL";

export const SEASON_CREATE_REQUEST = "SEASON_CREATE_REQUEST";
export const SEASON_CREATE_SUCCESS = "SEASON_CREATE_SUCCESS";
export const SEASON_CREATE_FAIL = "SEASON_CREATE_FAIL";
export const SEASON_CREATE_RESET = "SEASON_CREATE_RESET";

export const SEASON_UPDATE_REQUEST = "SEASON_UPDATE_REQUEST";
export const SEASON_UPDATE_SUCCESS = "SEASON_UPDATE_SUCCESS";
export const SEASON_UPDATE_FAIL = "SEASON_UPDATE_FAIL";
export const SEASON_UPDATE_RESET = "SEASON_UPDATE_RESET";

