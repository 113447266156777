import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import classes from './Giftcards.module.css'
import axios from 'axios'
import { toast } from "react-toastify";
import Toast from "../LoadingError/Toast";
import { BASE_URL } from '../../api/baseConfig'
const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

function GiftcardAdd() {



    const [packages, setPackages] = React.useState([])
    const [promoInfo, setPromoInfo] = React.useState({})
    const [promoData, setPromoData] = React.useState([])


    const name = useRef()
    const discount = useRef()
    const packageRef = useRef()
    const count = useRef()
    const forUse = useRef()
    const isCompany = useRef()



    React.useEffect(() => {
        axios
        .get(`${BASE_URL}/api/package/getall/AZ`)
        .then((res) => {
            setPackages(res.data.data)
        })
    }, [])


    const promoInfoHandler = React.useCallback((e) => {
        setPromoInfo({
            'name' : name.current.value ,
            'count' : count.current.value ,
            'discount' : discount.current.value ,
            'package_id' : packageRef.current.value ,
            'isCompany' : isCompany.current.checked ,
            'for_use' : forUse.current.value
            })
    },[])

    const SubmitHandler = React.useCallback((e) => {
        e.preventDefault()
        axios
        .post(`${BASE_URL}/api/promo/add`, promoInfo)
        .then((res) => {
            toast.success("Promocode Created!", ToastObjects);
            setPromoData(res.data)
        })
    },[promoInfo])

  return (
    <>
    <Toast />
    <section className="content-main">
            <div className="content-header">
                <h2 className="content-title">Add Giftcards</h2>
            </div>

            <div className="card mb-4 shadow-sm">
                <header className="card-header bg-white">
                    <div className="row gx-3 py-3">
                        <div className="col-lg-2 col-6 col-md-3">
                            <Link to="/giftcards" className="btn btn-danger rounded px-3 py-2">Back</Link>
                        </div>
                    </div>
                </header>
                <div className="card-body p-4">
                        <form onSubmit={SubmitHandler}>
                            <div className="row mb-3">
                                <label htmlFor="inputName">Promo Name</label>
                                <input ref={name} required type="text" className={classes.input + " form-check-input"} id="inputName" />
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="inputDiscount">Discount</label>
                                <input ref={discount} required type="number" className={classes.input + " form-check-input"} id="inputDiscount" />
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="inputDiscount">Package</label>
                                <select ref={packageRef} className={classes.input + " form-select"} id="inputPackage">
                                    {packages && packages.map((packege) => (
                                        <option key={packege.id} value={packege.id}>{packege.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="inputCount">Count</label>
                                <input ref={count} required type="number" className={classes.input + " form-check-input"} id="inputCount" />
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="inputForUse">For Use</label>
                                <input ref={forUse} required type="number" className={classes.input + " form-check-input"} id="inputForUse" />
                            </div>

                            <div className="row mb-3 d-flex flex-row justify-content-start">
                                <label className={classes.isCompany} htmlFor="inputIsCompany">Is Company</label>
                                <input ref={isCompany} className={classes.isCompanyCheck} type={"checkbox"} id="inputIsCompany" />
                            </div>
                            <button onClick={promoInfoHandler} type='submit' className="btn btn-primary rounded px-3 py-2">Add Giftcard</button>
                        </form>
                        <h3 className='mt-4'>Codes:</h3>
                        <div className='mt-3 ms-4'>
                            <span>[</span>
                            {promoData && promoData.map((promo) => (
                                <span>{promo}, </span>
                            ))}
                            <span>]</span>
                        </div>
                </div>
            </div>
        </section>

        <section>
                
        </section>
    </>
  )
}

export default GiftcardAdd