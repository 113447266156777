export const ACTOR_LIST_REQUEST = "ACTOR_LIST_REQUEST";
export const ACTOR_LIST_SUCCESS = "ACTOR_LIST_SUCCESS";
export const ACTOR_LIST_FAIL = "ACTOR_LIST_FAIL";

export const ACTOR_EDIT_REQUEST = "ACTOR_EDIT_REQUEST";
export const ACTOR_EDIT_SUCCESS = "ACTOR_EDIT_SUCCESS";
export const ACTOR_EDIT_FAIL = "ACTOR_EDIT_FAIL";

export const ACTOR_DELETE_REQUEST = "ACTOR_DELETE_REQUEST";
export const ACTOR_DELETE_SUCCESS = "ACTOR_DELETE_SUCCESS";
export const ACTOR_DELETE_FAIL = "ACTOR_DELETE_FAIL";

export const ACTOR_CREATE_REQUEST = "ACTOR_CREATE_REQUEST";
export const ACTOR_CREATE_SUCCESS = "ACTOR_CREATE_SUCCESS";
export const ACTOR_CREATE_FAIL = "ACTOR_CREATE_FAIL";
export const ACTOR_CREATE_RESET = "ACTOR_CREATE_RESET";

export const ACTOR_UPDATE_REQUEST = "ACTOR_UPDATE_REQUEST";
export const ACTOR_UPDATE_SUCCESS = "ACTOR_UPDATE_SUCCESS";
export const ACTOR_UPDATE_FAIL = "ACTOR_UPDATE_FAIL";
export const ACTOR_UPDATE_RESET = "ACTOR_UPDATE_RESET";
