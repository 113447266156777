import { BASE_URL } from './baseConfig'
import axios from 'axios';

function getHeaderWithToken() {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return {'x-access-token': token}
}

export function getInteractiveFilms() {
    return axios.get(BASE_URL + '/api/filter/contentsoptional/AZ?type=interactive')
}

export function getInteractiveScenarios(filmId) {
    const url = `${BASE_URL}/api/interactive/scenario/getall/${filmId}`
    return axios.get(url)
}

export async function getInteractiveScenario(scenarioId) {
    const url = `${BASE_URL}/api/interactive/scenario/getbyid/${scenarioId}`
    return axios.get(url)
}

export function deleteInteractiveFilm(id) {
    const tokenHeader = getHeaderWithToken()
    return axios.delete(
        `${BASE_URL}/api/film/delete/${id}`,
        {headers: tokenHeader}
    )
}

export function updateInteractiveScenario(scenarioId, data) {
    const url = `${BASE_URL}/api/interactive/scenario/update/${scenarioId}`
    return axios.put(url, data)
}

export function deleteInteractiveScenario(scnearioId) {
    const url = `${BASE_URL}/api/interactive/scenario/delete/${scnearioId}`
    return axios.delete(url)
}

export function addScenario(filmId, scenario) {
    const url = `${BASE_URL}/api/interactive/scenario/${filmId}/add`
    return axios.post(url, scenario)
}