import React from "react";
import axios from 'axios'
import { BASE_URL } from "../../api/baseConfig";
import { useHistory } from "react-router-dom";
import classes from './Subscription.module.css'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { toast } from "react-toastify";
import Toast from "../LoadingError/Toast";
const ToastObjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClose: 2000,
  };

const initialState = {
    month: '',
    price: '',
    // discount: '',
    language: [
        {lang_code: 'AZ', name: '', description: ''},
        {lang_code: 'EN', name: '', description: ''},
        {lang_code: 'TR', name: '', description: ''},
        {lang_code: 'RU', name: '', description: ''}
    ],
    
  }
  const editMonthAction = (state, action) => {
    return {...state, month: action.month}
  }
  const editPriceAction = (state, action) => {
    return {...state, price: action.price}
  }
//   const editDiscountAction = (state, action) => {
//     return {...state, discount: action.discount}
//   }
  const editNameAction = (state, action) => {
    const newLanguage = [...state.language]
    const langIndex = newLanguage.findIndex(e => e.lang_code === action.lang_code)
    const langInfo = newLanguage[langIndex]
    const newLangInfo = {...langInfo, [action.key]: action.value}
    newLanguage[langIndex] = newLangInfo
    return {...state, language: newLanguage}
  }
  
  const reducer = (state, action) => {
    switch(action.type) {
      case 'EDIT_MONTH': return editMonthAction(state, action);
      case 'EDIT_PRICE': return editPriceAction(state, action);
    //   case 'EDIT_DISCOUNT': return editDiscountAction(state, action);
      case 'EDIT_NAME': return editNameAction(state, action);
      default: return state;
    }
  }
  



function SubscriptionAdd() {

    const [state, dispatch] = React.useReducer(reducer, initialState)

  
    
    let history = useHistory();
    

    

  
    const monthChangeHandler = React.useCallback((e) => {
      dispatch ({
        type: 'EDIT_MONTH',
        month: e.target.value
      })
    }, [])
    const priceChangeHandler = React.useCallback((e) => {
      dispatch ({
        type: 'EDIT_PRICE',
        price: e.target.value
      })
    }, [])
    // const discountChangeHandler = React.useCallback((e) => {
    //   dispatch ({
    //     type: 'EDIT_DISCOUNT',
    //     discount: e.target.value
    //   })
    // })
    const nameChangeHandler = React.useCallback((key, value, lang_code) => {
      dispatch ({
        type: 'EDIT_NAME',
        value: value,
        key: key,
        lang_code,
      })
    }, [])
  
    
  
    const submitHandler = React.useCallback(() => {
        const token = JSON.parse(localStorage.getItem('userInfo')).token
        axios.post(BASE_URL + '/api/package/add', {
          language : state.language,
          month: state.month,
          price: state.price,
          discount: state.discount,
        }, {
          headers: {'x-access-token': token}
          })
          .then(response => {
              toast.success('Package updated', ToastObjects)
            }).catch(error => {
                toast.error('Error occured', ToastObjects)
          })
      }, [state])

  return (
    <section className="content-main" style={{ maxWidth: '1200px' }}>
        <Toast />
      <div className="content-header">
            <button onClick={() => {history.goBack()}} className="btn btn-danger text-white">
              Go Back
            </button>
            <h2 className="content-title">Subscription Plan</h2>
            <div>
              <button className="btn btn-primary" onClick={submitHandler}>
                Save
              </button>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm">
                {
                  state
                  ?
                <>
                  <div className="card-body">
                    <h5>Month</h5>
                    <input type='number' onChange={monthChangeHandler} value={state.month} className={classes.inputField + ' form-control'} /> <h6 className="d-inline">month(s)</h6>
                  </div>
                  <div className="card-body">
                    <h5>Price</h5>
                    <input type="number" onChange={priceChangeHandler} value={state.price} className={classes.inputField + ' form-control'} /> <h6 className="d-inline">₼</h6>
                  </div>
                  {/* <div className="card-body">
                    <h5>Discount</h5>
                    <input type="number" onChange={discountChangeHandler} value={state.discount} className={classes.inputField + ' form-control'} />
                  </div> */}
                </>
                : null
                }
                <div className={classes.tabDiv}>
                <Tabs>
                        {state &&
                          state.language &&
                          state.language.map((l, index) => (
                            <Tab
                              eventKey={l.lang_code}
                              key={l.lang_code}
                              title={l.lang_code}
                            >
                              <div className="mb-4">
                                <label
                                  className="form-label"
                                >
                                  Title
                                </label>
                                <input
                                  type="text"
                                  placeholder="Type here"
                                  className="form-control"
                                  name="name"
                                  required
                                  value={l.name}
                                  onChange={(e) => nameChangeHandler('name', e.target.value, l.lang_code)}
                                />
                              </div>

                              <div className="mb-4">
                                <label className="form-label">
                                  Description
                                </label>
                                <textarea
                                  placeholder="Type here"
                                  className="form-control"
                                  name={`description`}
                                  rows="7"
                                  value={l.description}
                                  onChange={(e) => nameChangeHandler('description', e.target.value, l.lang_code)}
                                ></textarea>
                              </div>
                            </Tab>
                          ))}
                      </Tabs>
                      </div>
              </div>
            </div>
          </div>
    </section>
  )
}

export default SubscriptionAdd