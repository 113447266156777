import React from 'react'
import { Link } from 'react-router-dom'
import GiftcardsList from '../Giftcards/GiftcardsList'
import axios from 'axios'
import { BASE_URL } from '../../api/baseConfig'

function GiftcardsListMain() {

    const [packages, setPackages] = React.useState([])
    const [filterInfo, setFilterInfo] = React.useState({})
    const [isFiltered, setIsFiltered] = React.useState(false)
    
    const packageRef = React.useRef()
    const companyRef = React.useRef()
    const discountRef = React.useRef()
    const codeRef = React.useRef()

    React.useEffect(() => {
        axios
        .get(`${BASE_URL}/api/package/getall/AZ`)
        .then((res) => {
            setPackages(res.data.data)
        })
    }, [])

    const filterInfoHandler = React.useCallback(() => {
        setIsFiltered(true)
        setFilterInfo({
            'package_id' : packageRef.current.value ,
            'company' : companyRef.current.value,
            'discount' : discountRef.current.value,
            'code' : codeRef.current.value
        })
    },[])

    React.useEffect(() => {
        if(filterInfo.package_id === "" && filterInfo.company === "" && filterInfo.discount === "" && filterInfo.code === ""){
            setIsFiltered(false)
        }
    },[filterInfo])

    
  return (
    <section className="content-main">
            <div className="content-header">
                <h2 className="content-title">Giftcards List</h2>
                <Link to="/giftcards/add" className="btn btn-primary btn-sm rounded">Add Giftcard</Link>
            </div>

            <div className="card mb-4 shadow-sm">
                <header className="card-header bg-white">
                    <div className="row gx-3 py-3">
                        <div className="col-lg-2 col-6 col-md-3">
                        </div>
                        <div>
                            <form className="row gx-1 gy-2 align-items-center gap-4">
                                
                                <div className={"col-auto"}>
                                    <label className="" htmlFor="autoSizingInput">Package</label>
                                    <select onChange={filterInfoHandler} ref={packageRef} className="form-select">
                                        <option value="">All</option>
                                        {packages && packages.map((packege) => (
                                            <option key={packege.id} value={packege.id}>{packege.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-auto">
                                    <label className="" htmlFor="autoSizingInput">Company</label>
                                    <input onChange={filterInfoHandler} ref={companyRef} type="text" className="form-control" id="autoSizingInput" placeholder="Search..." />
                                </div>
                                <div className="col-auto">
                                    <label className="" htmlFor="autoSizingInput">Discount</label>
                                    <input onChange={filterInfoHandler} ref={discountRef} type="number" className="form-control" id="autoSizingInput" placeholder="Search..." />
                                </div>
                                <div className="col-auto">
                                    <label className="" htmlFor="autoSizingInput">Code</label>
                                    <input onChange={filterInfoHandler} ref={codeRef} type="text" className="form-control" id="autoSizingInput" placeholder="Search..." />
                                </div>
                            </form>
                        </div>
                    </div>
                </header>
                <div className="card-body">
                    <div className="table-responsive">
                        <GiftcardsList filterInfo={filterInfo} isFiltered={isFiltered}/>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default GiftcardsListMain