import React, { useCallback, useEffect, useState } from "react";
import CreateContentType from "./CreateContentType";
import ContentTypesTable from "./ContentTypesTable";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { BASE_URL } from "../../api/baseConfig";
import { langTypes } from "../../types";
import { toast } from "react-toastify";

export interface IContentTypeSummary {
  id: string,
  name: string,
  slug: string,
}

export type ContentTypeSummaryList = IContentTypeSummary[];

export interface ILanguage {
  lang_code: string,
  name: string,
}

export interface IContentTypeEdit {
    "id": string,
    "language": ILanguage[],
    "slug": string
}
export interface IContentTypeCreate {
    "language": ILanguage[],
    "slug": string
}

interface IMainContentTypeProps {

}

const MainContentType = (props: IMainContentTypeProps) => {
  const [page, setPage] = useState(1);
  const [contentTypeToEdit, setContentTypeToEdit] = useState<IContentTypeEdit|null|IContentTypeCreate>(null);
  const [contentTypes, setContentTypes] = useState<ContentTypeSummaryList|null>(null);
  const [contentTypesPage, setContentTypesPage] = useState<number|null>(null);
  const token = JSON.parse(localStorage.getItem("userInfo")!).token;

  const loadContentTypes = React.useCallback((page: number, lang: langTypes) => {
    axios
      .get(BASE_URL + `/api/contenttype/getall/${lang}?page=${page}`, {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        setContentTypes(response.data);
        setContentTypesPage(response.data.totalPage);
      });
  }, [token]);

  const getContentTypeById = React.useCallback((id: number) => {
    axios
      .get(BASE_URL + `/api/contenttype/getbyid/${id}`, {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        setContentTypeToEdit(response.data);
      });
  }, [token]);



  useEffect(() => {
    loadContentTypes(page, 'AZ');
  }, [loadContentTypes, page]);

  const handlePageClick = (event: { selected: number }) => {
    setPage(event.selected + 1);
  };

  const deleteHandler = useCallback((id: string) => {
    axios
      .delete(BASE_URL + `/api/contenttype/delete/${id}`, {
        headers: { "x-access-token": token },
        })
      .then(() => {
        setContentTypes(prevState => (prevState!.filter((contentType) => contentType.id !== id)));
        toast.success("Successfully deleted");
      });
      }, [token])

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">ContentTypes</h2>
      </div>

      <div className="card shadow-sm">
        <div className="card-body">
          <div className="row">
            {/* Create contentType */}
            <CreateContentType contentType={contentTypeToEdit} onCancelEdit={() => setContentTypeToEdit(null)} loadContentTypes={loadContentTypes} page={page}/>
            {/* ContentTypes table */}
            <ContentTypesTable contentTypes={contentTypes} getContentType={getContentTypeById} onDelete={deleteHandler}/>
          </div>
          <div className="d-flex justify-content-end">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next>"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={contentTypesPage ? Math.ceil(contentTypesPage / 15) : 1}
              previousLabel="< previous"
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            // renderOnZeroPageCount={1}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainContentType;