import React from 'react'
import Sidebar from "../components/sidebar";
import Header from '../components/Header';
import PaymentsMain from '../components/Payments/PaymentsMain';

function PaymentsScreen() {
  return (
    <>
        <Sidebar />
        <main className="main-wrap">
            <Header />
            <PaymentsMain />
        </main>

    </>
  )
}

export default PaymentsScreen