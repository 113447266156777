import React from 'react'
import { Link } from 'react-router-dom'
import PaymentsList from '../Payments/PaymentsList'
import axios from 'axios'
import { BASE_URL } from '../../api/baseConfig'

function PaymentsMain() {

    const [packages, setPackages] = React.useState([])
    const [isFiltered, setIsFiltered] = React.useState(false);
    const [filterInfo, setFilterInfo] = React.useState({})

    const packageRef = React.useRef()
    const codeRef = React.useRef()
    const emailRef = React.useRef()

    React.useEffect(() => {
        axios
        .get(`${BASE_URL}/api/package/getall/AZ`)
        .then((res) => {
            setPackages(res.data.data)
        })
    }, [])

    const filterInfoHandler = React.useCallback(() => {
        setIsFiltered(true)
        setFilterInfo({
            'package_id' : packageRef.current.value ,
            'promo_code' : codeRef.current.value ,
            'email' : emailRef.current.value ,
        })
    },[])

    React.useEffect(() => {
        if(filterInfo.package_id === "" && filterInfo.promo_code === "" && filterInfo.email === ""){
            setIsFiltered(false)
        }
    },[filterInfo])
    
  return (
    <section className="content-main">
            <div className="content-header">
                <h2 className="content-title">Payments List</h2>
                <Link to='/add-giftcard' />
            </div>

            <div className="card mb-4 shadow-sm">
                <header className="card-header bg-white">
                    <div className="row gx-3 py-3">
                        <div className="col-lg-2 col-6 col-md-3 d-flex gap-3">
                            <div className={"col-auto"}>
                                <label className="" htmlFor="autoSizingInput">Package</label>
                                <select ref={packageRef} onChange={filterInfoHandler} className="form-select">
                                    <option value="">All</option>
                                    {packages && packages.map((packege) => (
                                        <option key={packege.id} value={packege.id}>{packege.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-auto">
                                <label className="" htmlFor="autoSizingInput">Promocode</label>
                                <input onChange={filterInfoHandler} ref={codeRef} type="text" className="form-control" id="autoSizingInput" placeholder="Search..." />
                            </div>
                            <div className="col-auto">
                                <label className="" htmlFor="autoSizingInput">Email</label>
                                <input onChange={filterInfoHandler} ref={emailRef} type="text" className="form-control" id="autoSizingInput" placeholder="Search..." />
                            </div>
                        </div>
                    </div>
                </header>
                <div className="card-body">
                    <div className="table-responsive">
                        <PaymentsList  isFiltered={isFiltered} filterInfo={filterInfo}/>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default PaymentsMain