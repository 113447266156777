import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { editPlatformRef, listPlatformRefs } from "../../Redux/Actions/PlatformRefActions";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
import CreatePlatformsRef from "./CreatePlatformRefs";
import PlatformRefsTable from "./PlatformRefsTable";
const MainPlatformRefs = (props) => {
  const dispatch = useDispatch();
  // const [q, setQ] = useState("");
  let [page, setPage] = useState(1);
  const platformRefId=props.platformRefId??null;
  const platformRefList = useSelector((state) => state.platformRefList);
  const { loading, error, platformRefs } = platformRefList;
 const platformEdit = useSelector((state) => state.platformRefEdit);
  const {  platformRef } = platformEdit;
  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };
  useEffect(() => {
    if (platformRefId !== null) {
      dispatch(editPlatformRef(platformRefId));
    }
  }, [platformRefId, dispatch]);
  useEffect(() => {
    dispatch(listPlatformRefs({page}));
  }, [dispatch,page]);
  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Platforms</h2>
      </div>
      <div className="card shadow-sm">
        {loading && <Loading/> && error }
        {error && <Message variant="alert-danger">{error}</Message>}
        <div className="card-body">
          <div className="row">
            {/* Create Platform */}
            <CreatePlatformsRef platformEdit={platformEdit} platformRef={platformRef}/>
            {/* Platform table */}
            <PlatformRefsTable platformRefs={platformRefs.data??[]}/>
          </div>
          <div className="d-flex justify-content-end">
          <ReactPaginate
              breakLabel="..."
              nextLabel="next>"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={platformRefs.data ? Math.ceil( platformRefs.totalPage / 3):1}
              previousLabel="< previous"
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
              renderOnZeroPageCount={1}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainPlatformRefs;
