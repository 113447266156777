import Header from '../components/Header';
import Sidebar from '../components/sidebar';
import InteractiveScenarios from '../components/Interactive/InteractiveScenarios'
import * as React from 'react';

export interface InteractiveScenariosScreenProps {
}

export default function InteractiveScenariosScreen(props: InteractiveScenariosScreenProps) {
    return (
        <>
            <Sidebar />
            <main className="main-wrap">
                <Header />
                <InteractiveScenarios />
            </main>
        </>
    );
}
