import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../api/baseConfig";

const PartnersStatistics = () => {

    const [statisctis, setStatistics] = useState(false);


    const [data, setData] = useState([]);

    useEffect(() => {
        const { _id } = JSON.parse(localStorage.getItem('userInfo')).user;
        const fetchData = async () => {
            const { data } = await axios.get(`${BASE_URL}/api/partner/film/AZ/${_id}`)

            setData(data.message);
        }

        fetchData()


    }, [])

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Movie poster</th>
                        <th scope="col">Movie Name</th>
                        <th scope="col">Price</th>
                        <th scope="col">Views</th>
                        <th scope="col">Paid</th>
                        <th scope="col">Statistics</th>
                    </tr>
                </thead>
                <tbody>
                    {data && data.map(item => (
                        <tr key={item.name}>
                            <td>
                                <img style={{objectFit: "cover"}} width={200} height={200} src={item.main_picture} alt={item.name} />
                            </td>
                            <td>
                                <a href={`https://metaflix.az/category/film/${item.slug}`}
                                    target='_blank'
                                    rel="noreferrer">{item.name}</a>
                            </td>
                            <td>{item.price} ₼</td>
                            <td>11,222</td>
                            <td>92,6589 ₼</td>
                            <td>
                                <button
                                    onClick={() => setStatistics(!statisctis)}
                                    className="btn btn-success">
                                    {statisctis ? 'Hide Statistics' : 'Show Statistics'}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className={`${statisctis ? 'd-block' : 'd-none'}`}>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Day</th>
                            <th scope="col">Week</th>
                            <th scope="col">Month</th>
                            <th scope="col">Year</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <p>1232</p>
                                <p>112₼</p>
                            </td>
                            <td>
                                <p>5511</p>
                                <p>708₼</p>
                            </td>
                            <td>
                                <p>42131</p>
                                <p>2412₼</p>
                            </td>
                            <td>
                                <p>663231</p>
                                <p>18888₼</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </>


    );
}


export default PartnersStatistics