import React, {  useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { listCatalogs } from "../../Redux/Actions/CatalogActions";
import { contentTypeList } from "../../Redux/Actions/ContentTypeActions";
import { listPlatforms } from "../../Redux/Actions/PlatformActions";
import {
  platformRefCreate,
  updatePlatformRefs,
} from "../../Redux/Actions/PlatformRefActions";
import {
  PLATFORM_REF_CREATE_RESET,
  PLATFORM_REF_UPDATE_RESET,
} from "../../Redux/Constants/PlatformRefConstants";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
import Toast from "../LoadingError/Toast";
const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};
const CreatePlatformsRef = (platInfo) => {
  const [mainRefInfo,setMainRefInfo]=useState(null)
  const history = useHistory();
  const [maincontentType, setMaincontentType] = useState("");
  const [rowNumber, setRowNumber] = useState(0);

  const [platform, setPlatform] = useState("");
  const [catalog, setCatalog] = useState("");
  const dispatch = useDispatch();
  const platformUpdate = useSelector((state) => state.platformRefUpdate);
  const mainplatformCreate = useSelector((state) => state.platformRefCreate);
  const platformListInfo = useSelector((state) => state.platformList);
  const {loading:loadingPlat, platforms } = platformListInfo;
  const { loading: loadingUpdate, success: successUpdate } = platformUpdate;
  const { loading: loadingCreate, success: successCreate } = mainplatformCreate;
  useEffect(() => {
    if (successUpdate) {
      toast.success("Platform Referance Updated", ToastObjects);
      dispatch({ type: PLATFORM_REF_UPDATE_RESET });
    }
  }, [dispatch, successUpdate]);

  useEffect(() => {
    if (successCreate) {
      toast.success("Platform Added", ToastObjects);
      dispatch({ type: PLATFORM_REF_CREATE_RESET });
    }
  }, [dispatch, successCreate]);

  
  useEffect(()=>{
    if(platInfo.platformRef.platform){
      setPlatform(platInfo.platformRef.platform)
      setCatalog(platInfo.platformRef.catalog)
      setMaincontentType(platInfo.platformRef.type)
      setMainRefInfo(platInfo)
      setRowNumber(platInfo.platformRef.row_number)
    }
  },[platInfo])
  const platformInfo = useSelector((state) => state.platformCreate);
  const catalogInfo = useSelector((state) => state.catalogList);
  const { catalogs } = catalogInfo;
  const { loading, error } = platformInfo;
  const contentTypeInfo = useSelector((state) => state.contentType);
  const { contentType } = contentTypeInfo;
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(platformRefCreate({ catalog, content_type:maincontentType, platform,row_number:rowNumber }));
    setMainRefInfo(null)
    setCatalog("");
    setMaincontentType("");
    setPlatform("");
    history.replace("/platform-ref");
  };
  const submitHandlerEdit = (e) => {
    dispatch(
      updatePlatformRefs({id:platInfo.platformRef.id, 
        catalog,
        content_type:maincontentType,
        platform,
        row_number:rowNumber,
        old_row_number:platInfo.platformEdit?platInfo.platformEdit.platformRef.row_number:null
      })
    );
    setMainRefInfo(null)
    setCatalog("");
    setRowNumber(0);
    setMaincontentType("");
    setPlatform("");
    history.replace("/platform-ref");
  };
  useEffect(() => {
    dispatch(listPlatforms());
    dispatch(listCatalogs({lang:"AZ",page:1}));
    dispatch(contentTypeList("AZ"))
  }, [dispatch]);
  // useEffect(() => {
  //   if (platInfo.platformRef.id) {
  //     setCatalog(platformRef.catalog);
  //     setPlatform(platformRef.platform);
  //     setMaincontentType(platformRef.content_type);
  //   }
  // }, [platformRef]);
    const cancelEventHandler=()=>{
      setMainRefInfo(null)
      setPlatform("");
      setCatalog("");
      setRowNumber(0);
      setMaincontentType("");
      // history.replace("/platform-ref");
    }
  
  // useEffect(()=>{
  //   history.replace("/platform-ref");
  //   history.go()    
  // },[history])
  // const navigateTo = useRef(history.push).current;
  // const handleChangeUrl=useCallback(
  //    () => {
  //     cancelEventHandler();
  //     navigateTo('/platform-ref')
  //   },
  //   [navigateTo],
  // )
  // useEffect(()=>{
  //   handleChangeUrl();
  // },[handleChangeUrl])  
  const handleGetCatalog =(value)=>{
    dispatch(listCatalogs({lang:"AZ",page:1,q:value}));

  }
  const handleChangeCatalogs = (option) => {
    setCatalog(option)
  };
  return (
    <>
      <Toast />
      <div className="col-md-12 col-lg-4">
        <form
          onSubmit={platInfo.platformRef.platform ? submitHandlerEdit : submitHandler}
        >
          {error && <Message variant="alert-danger">{error}</Message>}
          {loadingUpdate && <Loading />}
          {loading && <Loading />}
          {loadingPlat && <Loading />}
          {loadingCreate && <Loading />}
          {/* <div className="mb-4">
            <label className="form-label">Platforms</label>
            <select
              defaultValue={platInfo.platformRef?platInfo.platformRef.platform:"DEFAULT"}
              className="form-control"
              onChange={(e) => setPlatform(e.target.value)}
            >
              {platforms &&
                platforms.map((p) => (
                  <option value={p.id} key={p.id}>
                    {p.name}
                  </option>
                ))}
            </select>
          </div> */}
          <div className="mb-4">
                          <label type="number" className="form-label">Sıra nömrəsi</label>
                          <input required={true} value={rowNumber} onChange={(e)=>setRowNumber(Number(e.target.value))} className="form-control" placeholder="0"/>
                        </div>
                        <div className="mb-4">
                          <label className="form-label">Platforms</label>
                          <Select
                            onChange={(e) => setPlatform({id:e.id,name:e.name})}
                            id="platform"
                            options={platforms}
                            defaultValue={platform}
                            value={platform}
                            required={true}
                            getOptionLabel={(opt) => opt.name}
                            getOptionValue={(opt) => opt.id}
                          />
                        </div>
                        <div className="mb-4">
                          <label className="form-label">Catalogs</label>
                          <div className="mb-4">
                            {catalogs && (
                              <Select
                                onChange={handleChangeCatalogs}
                                onInputChange={handleGetCatalog}
                                options={catalogs.data}
                                value={catalog}
                                required={true}
                                getOptionLabel={(opt) => opt.name}
                                getOptionValue={(opt) => opt.id}
                              />
                            )}
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="form-label">Type</label>
                          <Select
                            onChange={(e) => setMaincontentType({id:e.id,name:e.name})}
                            id="platform"
                            options={contentType}
                            defaultValue={maincontentType}
                            value={maincontentType}
                            getOptionLabel={(opt) => opt.name}
                            getOptionValue={(opt) => opt.id}
                            required={true}
                          />
                        </div>
          <div className="d-grid">
            {mainRefInfo ? (
              <>
                <button className="btn btn-warning py-3 mb-3" type="submit">
                  Edit Platform
                </button>
                <button
                  onClick={cancelEventHandler}
                  className="btn btn-secondary py-3"
                  type="button"
                >
                  Cancel
                </button>
              </>
            ) : (
              <button type="submit" className="btn btn-primary py-3">
                Create platform
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default CreatePlatformsRef;
