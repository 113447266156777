import * as React from 'react';
import Header from "../components/Header";
import Sidebar from "../components/sidebar";
import InteractiveScenarioForm from '../components/Interactive/InteractiveScenarioForm'

export interface IInteractiveScenarioFormProps {
}

export default function InteractiveScenarioFormScreen (props: IInteractiveScenarioFormProps) {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <InteractiveScenarioForm />
      </main>
    </>
  );
}
