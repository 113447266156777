import React from "react";
import { IMAGE_BUCKET_URL } from "../../api/baseConfig";
import axios from "axios";
import { Table } from "react-bootstrap";
import { BASE_URL } from "../../api/baseConfig";

function PartnerViewsMain({ id }) {
  const [partner, setPartner] = React.useState({});
  const [info, setInfo] = React.useState([]);
  const token = JSON.parse(localStorage.getItem("userInfo")).token;
  React.useEffect(() => {
    axios
      .get(`${BASE_URL}/api/film/AZ/view/getbypartner/${id}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((res) => {
        setPartner(res.data.partner);
        setInfo(res.data.data);
      });
  }, [id, token]);
  return (
    <>
      <Table className="table" striped bordered hover>
        <thead>
          <tr className="text-center">
            <th>AD</th>
            <th>SOYAD</th>
            <th>EMAIL</th>
            <th>TELEFON</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td>
              <h5>{partner?.firstName}</h5>
            </td>
            <td>
              <h5>{partner?.lastName}</h5>
            </td>
            <td>
              <h5>{partner?.email}</h5>
            </td>
            <td>
              <h5>{partner?.phoneNumber}</h5>
            </td>
          </tr>
        </tbody>
      </Table>
      <div>
        <table className="table bordered">
          <thead>
            <tr className="text-center">
              <th>Şəkil</th>
              <th>Film adı</th>
              <th>Əlavə olunma tarixi</th>
              <th>Hər baxışa</th>
              <th>Baxış sayı</th>
              <th>Partnyorun məbləği </th>
            </tr>
          </thead>
          <tbody>
            {info?.map((item, index) => (
              <Tr item={item} key={index} />
            ))}
          </tbody>
          
        </table>
      </div>
    </>
  );
}

export default PartnerViewsMain;

const Tr = ({ item }) => {
  const dateObj = new Date(item.add_date);
  const date = `${dateObj.getFullYear()}.${
    dateObj.getMonth() + 1
  }.${dateObj.getDate()}`;
  return (
    <tr className="text-center">
      <td>
        <img
          width={"80px"}
          height={"150px"}
          style={{ objectFit: "contain" }}
          src={IMAGE_BUCKET_URL + item.main_picture}
          alt=""
        />
      </td>
      <td>{item.name}</td>
      <td>{date}</td>
      <td>{item.cash} AZN</td>
      <td>{item.hit}</td>
      <td>{item.part} AZN</td>
    </tr>
  );
};
