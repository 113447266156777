import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Movies from "./Movies";
import { useDispatch, useSelector } from "react-redux";
import { listProducts } from "../../Redux/Actions/ProductActions";
import { listPlatforms } from "../../Redux/Actions/PlatformActions";
import { contentTypeList } from "../../Redux/Actions/ContentTypeActions";

import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import { listCatalogs } from "../../Redux/Actions/CatalogActions";
import ReactPaginate from "react-paginate";
const MainMovies = () => {
  let [page, setPage] = useState(1);
  const [platformId, setPlatformId] = useState("");
  const [catalogId, setCatalogId] = useState("");
  const [contentTypeId, setContentTypeId] = useState("");
  const contentTypeInfo = useSelector((state) => state.contentType);

  const { contentType } = contentTypeInfo ?? [];
  const [q, setQ] = useState("");
  const platformInfo = useSelector((state) => state.platformList);
  const catalogInfo = useSelector((state) => state.catalogList);
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;
  const productDelete = useSelector((state) => state.productDelete);
  const { error: errorDelete, success: successDelete } = productDelete;
  const { platforms } = platformInfo;
  const { catalogs } = catalogInfo;

  useEffect(() => {
    dispatch(
      listProducts({
        lang: "AZ",
        platformId,
        catalogId,
        q: q.toLowerCase(),
        type: contentTypeId ? contentTypeId.slug : "film",
        page,
      })
    );
    dispatch(listPlatforms());
    dispatch(listCatalogs({ lang: "AZ", page: 1, q: "" }));
    dispatch(contentTypeList("AZ"));
  }, [dispatch, successDelete, platformId, catalogId, page, q, contentTypeId]);
  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };
  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Movies</h2>
        <div>
          <Link to="/addMovie" className="btn btn-primary">
            Create new
          </Link>
        </div>
      </div>

      <div className="card mb-4 shadow-sm">
        <header className="card-header bg-white ">
          <div className="row gx-3 py-3">
            <div className="col-lg-4 col-md-6 me-auto ">
              <input
                type="search"
                placeholder="Search..."
                className="form-control p-2"
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
            </div>
            <div className="col-lg-2 col-6 col-md-3">
              <select
                defaultValue={""}
                className="form-select"
                onChange={(e) => setPlatformId(e.target.value)}
              >
                <option value="">Platform</option>
                {platforms &&
                  platforms?.map((p) => (
                    <option value={p.id} key={p.id}>
                      {p.name}
                    </option>
                  ))}
              </select>
            </div>
            {contentType && (
              <div className="col-lg-2 col-6 col-md-3">
                <select
                  defaultValue={"DEFAULT"}
                  className="form-control"
                  onChange={(e) =>
                    setContentTypeId({
                      id: e.target.value.split("$")[0],
                      slug: e.target.value.split("$")[1],
                    })
                  }
                >
                  <option value="DEFAULT" disabled>
                    Type
                  </option>
                  {contentType &&
                    contentType.map((p) => (
                      <option value={`${p.id}$${p.slug}`} key={p.id}>
                        {p.name}
                      </option>
                    ))}
                </select>
              </div>
            )}
            <div className="col-lg-2 col-6 col-md-3">
              <select
                defaultValue={""}
                className="form-select"
                onChange={(e) => setCatalogId(e.target.value)}
              >
                <option value="">Catalog</option>
                {catalogs &&
                  catalogs.data?.map((p) => (
                    <option value={p.id} key={p.id}>
                      {p.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </header>

        <div className="card-body">
          {errorDelete && (
            <Message variant="alert-danger">{errorDelete}</Message>
          )}
          {loading ? (
            <Loading />
          ) : error ? (
            <Message variant="alert-danger">{error}</Message>
          ) : (
            <>
            <h1 style={{width:"100%"}} className="btn btn-dark btn-lg">Ümumi: {products.total}</h1>
              {products.data && products.data.length > 0 && (
                <>
                  <div>
                    {/* Products */}
                    <div className="row">
                      {products.data?.map((product) => (
                        <Movies product={product} key={product.id} />
                      ))}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          <div className="d-flex justify-content-end">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next>"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={products?.total > 0 ? Math.ceil(products?.total / 20) : 1}
              previousLabel="< previous"
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
              renderOnZeroPageCount={2}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainMovies;
