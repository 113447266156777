import React, { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import axios from "axios";
import Loading from "../LoadingError/Loading";
import {BASE_URL} from "../../api/baseConfig"

function PaymentsList(props) {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [paymentsInfo, setPaymentsInfo] = useState({});
  const [loading,setLoading] =useState(false)


  const handlePageClick = (event) => {
    setPage(event.selected+1);
  };

  const loadPayments = React.useCallback((page) => {
    setLoading(true)
    const user= JSON.parse(localStorage.getItem("userInfo"))
 axios.get(BASE_URL+`/api/payment/checkpayments?card=${props.filterInfo.card}&oderoId=${props.filterInfo.id}&page=${page-1}&size=10`,
 {headers:{"x-access-token":user.token}})
      .then((res) => {
        setPaymentsInfo(res.data);
         setTotal(res.data.totalSize)
      });
      setLoading(false)
  }, [props.filterInfo]);

  useEffect(() => {

      loadPayments(page);

  }, [loadPayments,props, page]);

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Odero id</th>
            <th scope="col">ad</th>
            <th scope="col">soy ad</th>
            <th scope="col">email</th>
            <th scope="col">nömrəsi</th>
            <th scope="col">ödəniş tarixi</th>
            <th scope="col">kart nömrəsi</th>
            <th scope="col">qiymət</th>
            <th scope="col">ödənilmiş məbləğ</th>
            <th scope="col">bank</th>
            <th scope="col">kart sahibinin adı</th>
          </tr>
        </thead>
        <tbody>
          {
        loading ? (
        <Loading />
    ):paymentsInfo.data &&(
            paymentsInfo.data.map((p) => (
                <tr key={p.oderoId}>
                    <td>{p.oderoId}</td>
                    <td>{p.firstName}</td>
                    <td>{p.lastName}</td>
                    <td>{p.email}</td>
                    <td>{p.phoneNumber}</td>
                    <td>{p.createdDate}</td>
                    <td>{p.lastFourDigits}</td>
                    <td>{p.price}</td>
                    <td>{p.paidPrice}</td>
                    <td>{p.cardBrand}</td>
                    <td>{p.cardHolderName}</td>
                </tr>
            )))
          }

        </tbody>
      </table>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next>"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={
          total !== 0 ? Math.ceil(total / 10) : 1
        }
        previousLabel="< previous"
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
        renderOnZeroPageCount={1}
        forcePage={page - 1}
      />
    </>
  );
}

export default PaymentsList;
