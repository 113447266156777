import Sidebar from "../components/sidebar";
import Header from "../components/Header";
import { PartnerStatisticsMain } from '../components/PartnerStatistics'


const PartnerStatisticsScreen = () => {
    return (
        <>
            <Sidebar />
            <main className="main-wrap">
                <Header />
                <PartnerStatisticsMain />
            </main>
        </>
    )
}

export default PartnerStatisticsScreen