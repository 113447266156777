import React, { useEffect, useState } from "react";
import CreateActors from "./CreateActors";
import ActorsTable from "./ActorsTable";
import { useDispatch } from "react-redux";
import { editActors, listActors } from "../../Redux/Actions/ActorActions";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { BASE_URL } from "../../api/baseConfig";
import { toast } from "react-toastify";

const MainActors = (props) => {
  const dispatch = useDispatch();
  const actorId=props.actorId??null;
  const [actorList, setActorList] = useState([]);
  const [actorForEdit, setActorForEdit] = useState(null);
  const [page, setPage] = useState(1);
  const [actorsPage, setActorsPage] = useState(1);


  useEffect(() => {
    if (actorId !== null) {
      dispatch(editActors(actorId));
    }
  }, [actorId, dispatch]);
  useEffect(() => {
    dispatch(listActors({page,q:""}));
  }, [dispatch,page]);

  


  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const loadActors = React.useCallback(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    axios.get(BASE_URL + `/api/actors/getall?page=${page}`, {
      headers: {'x-access-token': token}
  })
    .then((response) => {
      setActorList(response.data.data)
      setActorsPage(response.data.totalPage)
    })
    .catch((error) => {
    })  
  }, [page]);

  const deleteHandler = React.useCallback((id) => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    axios
      .delete(BASE_URL + "/api/actors/delete/" + id, {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        loadActors()
        toast.success("Actor deleted successfully");
      })

  }, [loadActors])

  const createHandler = React.useCallback((name, mainPicture) => {
    let formData = new FormData();
    formData.append("name", name);
    formData.append("image", mainPicture);
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    axios
      .post(BASE_URL + "/api/actors/add", formData, {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        loadActors()
      })

  }, [loadActors])


  const getActorById = React.useCallback((id) => {
    setActorForEdit(actorList.find((actor) => actor.id === id))
  }, [actorList])


  const editHandler = React.useCallback((id, name, mainPicture) => {
    let formData = new FormData();
    formData.append("id", id);
    formData.append("name", name);
    formData.append("image", mainPicture);
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    axios
      .put(BASE_URL + `/api/actors/update/${id}`, formData, {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        loadActors()
      })

  }, [loadActors])




  useEffect(() => {
    loadActors()
  }, [loadActors])




  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Actors</h2>
      </div>

      <div className="card shadow-sm">
        <div className="card-body">
          <div className="row">
            {/* Create actor */}
            <CreateActors actor={actorForEdit} setActor={setActorForEdit} onCreate={createHandler} onEdit={editHandler}/>
            {/* Actors table */}
            <ActorsTable actors={actorList ? actorList:[]} onDelete={deleteHandler} onEdit={getActorById}/>
          </div>
          <div className="d-flex justify-content-end">
              <ReactPaginate
              breakLabel="..."
              nextLabel="next>"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={actorList ? Math.ceil( actorsPage / 20):1}
              previousLabel="< previous"
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
              renderOnZeroPageCount={1}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainActors;
