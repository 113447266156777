import { deleteInteractiveScenario, getInteractiveScenarios } from '../../api/metaflixApi';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom'
import { IInteractiveScenario } from './interactiveTypes';
interface IInteractiveScenariosProps {
}



export default function InteractiveScenarios(props: IInteractiveScenariosProps) {
    const [scenarios, setScenarios] = React.useState<IInteractiveScenario[]>([])
    const { filmId } = useParams()

    React.useEffect(() => {
        getInteractiveScenarios(filmId).then(res => {
            setScenarios(res.data.data)
        })
    }, [filmId])

    const deleteScnearioHandler = React.useCallback((scenarioId) => {
        const answer = window.confirm('Are you sure to delete this scenario?')
        if (answer) {
            deleteInteractiveScenario(scenarioId).then(res => {
                setScenarios(prev => prev.filter(s => s._id !== scenarioId))
            })
        }
    }, [])

    return (
        <div>
            <section className="content-main">
                <div className="content-header">
                    <h2 className="content-title">Series</h2>
                    <div>
                        <Link to={`/interactive/${filmId}/scenarios/add`} className="btn btn-primary">
                            Create new
                        </Link>
                    </div>
                </div>
                <div className="card shadow-sm">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 bg-dark bg-dark text-white text-center p-2 h3">
                                Total 10 series
                            </div>
                        </div>
                        <div className="row">
                            {scenarios.map(scenario => {
                                const onDelete = () => deleteScnearioHandler(scenario._id)
                                return (
                                    <div key={scenario._id} className="col-6 col-md-4 col-lg-3">
                                        <InteractiveScnearioReference filmId={filmId} onDelete={onDelete} scenario={scenario} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

interface IInteractiveScnearioReferenceProps {
    filmId: string
    scenario: IInteractiveScenario
    onDelete: () => void
}

function InteractiveScnearioReference(props: IInteractiveScnearioReferenceProps) {

    return (
        <div className='border rounded p-2'>
            <div className='px-3 py-5 h1 text-center'>{props.scenario.name}</div>
            <div>
                <Link className="btn btn-sm btn-dark p-2 mb-2 pb-3 col-md-6" to={`/interactive/${props.filmId}/scenarios/${props.scenario._id}`}>
                    <i className="fas fa-pen"></i>
                </Link>
                <span className="btn btn-sm btn-danger p-2 mb-2 pb-3 col-md-6" onClick={props.onDelete}>
                    <i className="fas fa-trash-alt"></i>
                </span>
            </div>
        </div>
    )
}