import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import axios from 'axios'
import { BASE_URL } from "../../api/baseConfig";
import { useHistory } from "react-router-dom";
import classes from './Contact.module.css'

const ContactDetail = () => {


    let history = useHistory();



    const [contactInfo, setContactInfo] = useState({})
    const params = useParams()
    const contactId = params.id
    const [mailToLink, setMailToLink] = useState('')


    useEffect(() => {

        const token = JSON.parse(localStorage.getItem('userInfo')).token
        axios.get(BASE_URL + '/api/contact/getbyid/' + contactId, {
            headers: {'x-access-token': token}
        })
        .then(response => {
            setContactInfo(response.data)
            setMailToLink(`mailto:${response.data.data.email}`)  
        } )
    }, [contactId])

  return (
    <section className="content-main">
      <div className="content-header">
        <button className="btn btn-danger" onClick={() => history.goBack()}>Back</button>
        <h2 className="content-title">Contact Detail</h2>
      </div>

      <div className="card mb-4 shadow-sm">
        {/* <header className="card-header bg-white">
          <div className="row gx-3 py-3">

          </div>
        </header>
        <div className="card-body">        
        </div> */}
      </div>
      <div>
        <h5>Name Surname</h5>
        <p className={classes.nameField}>{contactInfo?.data?.name}</p>
      </div>
      <div>
        <h5>Email</h5>
        <p className={classes.emailField}>{contactInfo?.data?.email}</p> <a className="btn btn-success mb-1" href={mailToLink} target="_blank" rel="noopener noreferrer">Mail Back</a>
      </div>
      <div>
        <h5>Message</h5>
        <p className={classes.textField}>{contactInfo?.data?.text}</p>
      </div>
    </section>
  );
};

export default ContactDetail;
