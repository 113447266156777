import React from 'react'
import Sidebar from "../components/sidebar";
import Header from '../components/Header';
import OderoSubscriptionMain from '../components/odero/OderoSubscriptionMain';
function OderoSubscriptionScreen() {
  return (
    <>
        <Sidebar />
        <main className="main-wrap">
            <Header />
            <OderoSubscriptionMain/>
        </main>
    </>
  )
}

export default OderoSubscriptionScreen