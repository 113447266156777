import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { IMAGE_BUCKET_URL } from "../../api/baseConfig";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Table from "react-bootstrap/Table";
import { BASE_URL } from "../../api/baseConfig";
// ---------------------

// ---------------------
const PartnerScreenEdit = ({ partnerId }) => {
  const [partner, setPartner] = useState({});

  useEffect(() => {
    fetch(`${BASE_URL}/api/film/AZ/getbypartner/${partnerId}`)
      .then((res) => res.json())
      .then((json) => setPartner(json));
  }, [partnerId]);
  return (
    <>
   
      <section className="content-main">
      <div className="content-header">
                <h2 className="content-title">FİLMLƏR</h2>
            </div>

        <Table className="table" striped bordered hover>
          <thead>
            <tr className="text-center">
              <th>AD</th>
              <th>SOYAD</th>
              <th>EMAIL</th>
              <th>TELEFON</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <td>
                <h5>{partner?.partner?.firstName}</h5>
              </td>
              <td>
                <h5>{partner?.partner?.lastName}</h5>
              </td>
              <td>
                <h5>{partner?.partner?.email}</h5>
              </td>
              <td>
        <h5>{partner?.partner?.phoneNumber}</h5>

                
              </td>
            </tr>
          </tbody>
        </Table>
      </section>
      <section className="content-main">
        {!partner.data ? (
          // <h2 className="fs-4 text-center">Yüklənir...</h2>
          <>
            <Skeleton />
            <Skeleton count={5} />
            <Skeleton count={5} />
            <Skeleton count={5} />
            <Skeleton count={5} />
          </>
        ) : (
          <div>
          <table className="table bordered">
            <thead>
              <tr className="text-center">
                <th>Şəkil</th>
                <th>Film adı</th>
                <th>Qiymət</th>
                <th>Əlavə olunma tarixi</th>
                <th>Ümumi Məbləğ</th>
                <th>Faizi</th>
                <th>Satış sayı</th>
                <th>Partnyorun məbləği </th>
              </tr>
            </thead>
            <tbody>
              {partner.data?.map((item, index) => (
                <Tr item={item} key={index} />
              ))}
            </tbody>
          </table>
          <div className="d-flex flex-column align-items-end">
            <h4>Ümumi:{partner.payment_sum}</h4>
            <h4>Ödənildi:{partner.partner_payed}</h4>
            <h4>Qaldı:{partner.residual}</h4>
          </div>
          </div>
        )}
      </section>
    </>
  );
};

export default PartnerScreenEdit;

const Tr = ({ item }) => {
  // const dispatch = useDispatch();
  const dateObj =  new Date(item.add_date)
  const date = `${dateObj.getFullYear()}.${dateObj.getMonth()+1}.${dateObj.getDate()}`
  return (
    <tr className="text-center">
      <td>
        <img
          width={"80px"}
          height={"150px"}
          style={{ objectFit: "contain" }}
          src={IMAGE_BUCKET_URL + item.main_picture}
          alt=""
        />
      </td>
      <td>{item.name}</td>
      <td>{item.price}</td>
      <td>{date}</td>
      <td>{item.sum}</td>
      <td>{item.percent}</td>
      <td>{item.sale_count}</td>
      <td>{item.part}</td>
    </tr>
  );
};
