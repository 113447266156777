import React from "react";
import Header from "../components/Header";
import PartnerScreenEdit from "../components/PartnerScreenEdit/PartnerScreenEdit";
import Sidebar from "../components/sidebar";

const PartnerScreen = ({ match }) => {
  const partnerId = match.params.id;

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <PartnerScreenEdit partnerId={partnerId} />
      </main>
    </>
  );
};

export default PartnerScreen;
