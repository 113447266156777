import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Loading from '../LoadingError/Loading'
import {findUser, cancelSubscription} from '../../Redux/Actions/userActions'
import { BASE_URL } from '../../api/baseConfig'
import axios from 'axios';
import {useHistory} from 'react-router-dom'

const UserDetail = (props) => {
    const {email} = props
    const dispatch = useDispatch()
    const [user, setUser] = useState({})

    const history = useHistory()

    const userState = useSelector((state) => state.findUser)
    const {loading, payload, success} = userState
    // const subscriptionState = useSelector((state) => state.cancelSubscription);
    useEffect(() => {
        setUser(payload)
    }, [payload])
    useEffect(() => {
        dispatch(findUser({email}))
    }, [dispatch, email])

    const submitHandler = (email) => {
        dispatch(cancelSubscription({email}))
        alert('OKAY təkrar ödəmə ləğv edildi')
        let new_user = user
        new_user.subscribtion_payment = false
        setUser(new_user)
    }

    const onRemoveClick = () => {
        const answer = window.confirm('Are you sure you want to remove this user?')
        if (answer) {
            const token = JSON.parse(window.localStorage.getItem("userInfo")).token;
            axios.post(`${BASE_URL}/api/payment/removesubscribe`, {email: user.email}, {
                headers: { "x-access-token": token },
            }).then(res => {
                alert('User removed successfully')
                history.push('/users')
            })
        }
    }
    
    return (
        <>
            <div>
                {loading ? (
                    <Loading />
                ) : success ? (
                    user && (
                        <div>
                            <h3>email: {user.email}</h3>
                            <h3>ad: {user.firstName}</h3>
                            <h3>soy ad: {user.lastName}</h3>
                            <h3>qeydiyyat tarixi: {user.registerDate}</h3>
                            <h3>telefon nömrəsi: {user.phoneNumber}</h3>
                            <h3>rol: {user.claims}</h3>
                            <div className="d-flex">
                                <h3>
                                    təkrar ödəmə:{' '}
                                    {user.subscribtion_payment
                                        ? 'bəli'
                                        : 'xeyr'}
                                </h3>{' '}
                                {user.subscribtion_payment ? (
                                    <button
                                        onClick={() => {
                                            submitHandler(user.email)
                                        }}
                                        className="btn btn-danger"
                                    >
                                        ləğv et
                                    </button>
                                ) : null}
                            </div>
                            <h3>
                                ödəmənin keçərliliyi:{' '}
                                {user.payment_validity ? 'bəli' : 'xeyr'}
                            </h3>

                            {/* <h3>təkrar ödəmə: {user.subscribtion_payment ? "var":"yoxdur"}</h3> */}
                            <h3>son ödəmə: {user.last_payment_date}</h3>
                            <h3>növbəti ödəmə: {user.next_payment_date}</h3>
                            <h3>ödəmə kartı: {user.card}</h3>
                            <button className="btn btn-danger my-3" onClick={onRemoveClick}>
                                Remove User
                            </button>
                        </div>
                    )
                ) : (
                    <h1>{payload}</h1>
                )}
            </div>
        </>
    )
}

export default UserDetail
