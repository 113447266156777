export const DIRECTOR_LIST_REQUEST = "DIRECTOR_LIST_REQUEST";
export const DIRECTOR_LIST_SUCCESS = "DIRECTOR_LIST_SUCCESS";
export const DIRECTOR_LIST_FAIL = "DIRECTOR_LIST_FAIL";

export const DIRECTOR_EDIT_REQUEST = "DIRECTOR_EDIT_REQUEST";
export const DIRECTOR_EDIT_SUCCESS = "DIRECTOR_EDIT_SUCCESS";
export const DIRECTOR_EDIT_FAIL = "DIRECTOR_EDIT_FAIL";

export const DIRECTOR_DELETE_REQUEST = "DIRECTOR_DELETE_REQUEST";
export const DIRECTOR_DELETE_SUCCESS = "DIRECTOR_DELETE_SUCCESS";
export const DIRECTOR_DELETE_FAIL = "DIRECTOR_DELETE_FAIL";

export const DIRECTOR_CREATE_REQUEST = "DIRECTOR_CREATE_REQUEST";
export const DIRECTOR_CREATE_SUCCESS = "DIRECTOR_CREATE_SUCCESS";
export const DIRECTOR_CREATE_FAIL = "DIRECTOR_CREATE_FAIL";
export const DIRECTOR_CREATE_RESET = "DIRECTOR_CREATE_RESET";

export const DIRECTOR_UPDATE_REQUEST = "DIRECTOR_UPDATE_REQUEST";
export const DIRECTOR_UPDATE_SUCCESS = "DIRECTOR_UPDATE_SUCCESS";
export const DIRECTOR_UPDATE_FAIL = "DIRECTOR_UPDATE_FAIL";
export const DIRECTOR_UPDATE_RESET = "DIRECTOR_UPDATE_RESET";
