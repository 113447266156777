import React, { useCallback, useEffect, useState } from "react";
import CreateCatalog from "./CreateCatalog";
import CatalogsTable from "./CatalogsTable";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { BASE_URL } from "../../api/baseConfig";
import { langTypes } from "../../types";
import { toast } from "react-toastify";

export interface ICatalogSummary {
  id: string,
  name: string,
  slug: string,
}

export type CatalogSummaryList = ICatalogSummary[];

export interface ILanguage {
  lang_code: string,
  name: string,
}

export interface ICatalogEdit {
    "id": string,
    "language": ILanguage[],
    "slug": string
}
export interface ICatalogCreate {
    "language": ILanguage[],
    "slug": string
}

interface IMainCatalogProps {

}

const MainCatalog = (props: IMainCatalogProps) => {
  const [page, setPage] = useState(1);
  const [catalogToEdit, setCatalogToEdit] = useState<ICatalogEdit|null|ICatalogCreate>(null);
  const [catalogs, setCatalogs] = useState<CatalogSummaryList|null>(null);
  const [catalogsPage, setCatalogsPage] = useState<number|null>(null);
  const token = JSON.parse(localStorage.getItem("userInfo")!).token;


  const loadDirectors = React.useCallback((page: number, lang: langTypes) => {
    axios
      .get(BASE_URL + `/api/catalogs/getall/${lang}?page=${page}`, {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        setCatalogs(response.data.data);
        setCatalogsPage(response.data.totalPage);
      });
  }, [token]);

  const getCatalogById = React.useCallback((id: number) => {
    axios
      .get(BASE_URL + `/api/catalogs/getbyid/${id}`, {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        setCatalogToEdit(response.data);
      });
  }, [token]);



  useEffect(() => {
    loadDirectors(page, 'AZ');
  }, [loadDirectors, page]);

  const handlePageClick = (event: { selected: number }) => {
    setPage(event.selected + 1);
  };

  const deleteHandler = useCallback((id: string) => {
    axios
      .delete(BASE_URL + `/api/catalogs/delete/${id}`, {
        headers: { "x-access-token": token },
        })
      .then(() => {
        setCatalogs(prevState => (prevState!.filter((catalog) => catalog.id !== id)));
        toast.success("Successfully deleted");
      });
      }, [token])

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Catalogs</h2>
      </div>

      <div className="card shadow-sm">
        <div className="card-body">
          <div className="row">
            {/* Create catalog */}
            <CreateCatalog catalog={catalogToEdit} onCancelEdit={() => setCatalogToEdit(null)} loadDirectors={loadDirectors} page={page}/>
            {/* Catalogs table */}
            <CatalogsTable catalogs={catalogs} getCatalog={getCatalogById} onDelete={deleteHandler}/>
          </div>
          <div className="d-flex justify-content-end">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next>"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={catalogsPage ? Math.ceil(catalogsPage / 15) : 1}
              previousLabel="< previous"
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            // renderOnZeroPageCount={1}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainCatalog;