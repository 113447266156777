import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = () => {

  const { userInfo } = useSelector((state) => state.userLogin);

  return (
    <div>
      <aside className="navbar-aside" id="offcanvas_aside">
        <div className="aside-top">

          <Link to="/" className="brand-wrap">
            <img
              src="/images/logo.png"
              style={{ height: "46" }}
              className="logo"
              alt="Movie Dashboard"
            />
          </Link>
          <div>
            <button className="btn btn-icon btn-aside-minimize">
              <i className="text-muted fas fa-stream"></i>
            </button>
          </div>
        </div>

        <nav>
          <ul className="menu-aside">
            {(userInfo && userInfo.user.claims.includes("Admin")) ?
              <>
                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link d-flex align-items-center"
                    to="/"
                    exact={true}
                  >
                    <i className="icon fas fa-home"></i>
                    <span className="text">Dashboard</span>
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink
                    className="menu-link d-flex align-items-center collapsed"
                    to="#submenu1"
                    data-bs-target="#submenu1"
                    data-bs-toggle="collapse"
                    exact={true}
                  >
                    <i className="icon fas fa-list"></i>
                    <span className="text">Actions</span>
                  </NavLink>
                  <div className="collapse" id="submenu1" aria-expanded="false">
                    <ul>
                      <li className="menu-item">
                        <NavLink
                          className="menu-link d-flex align-items-center"
                          to="/actor"
                        >
                          <span className="text">Actors</span>
                        </NavLink>
                      </li>
                      <li className="menu-item">
                        <NavLink
                          className="menu-link d-flex align-items-center"
                          to="/director"
                        >
                          <span className="text">Directors</span>
                        </NavLink>
                      </li>
                      <li className="menu-item">
                        <NavLink
                          className="menu-link d-flex align-items-center"
                          to="/platform"
                        >
                          <span className="text">Platform</span>
                        </NavLink>
                      </li>
                      <li className="menu-item">
                        <NavLink
                          className="menu-link d-flex align-items-center"
                          to="/platform-ref"
                        >
                          <span className="text">Platform üçün kataloq</span>
                        </NavLink>
                      </li>
                      <li className="menu-item">
                        <NavLink
                          className="menu-link d-flex align-items-center"
                          to="/catalog"
                        >
                          <span className="text">Catalog</span>
                        </NavLink>
                      </li>
                      <li className="menu-item">
                        <NavLink
                          className="menu-link d-flex align-items-center"
                          to="/addActors"
                        >
                          <span className="text">Plan</span>
                        </NavLink>
                      </li>
                      <li className="menu-item">
                        <NavLink
                          className="menu-link d-flex align-items-center"
                          to="/contentType"
                        >
                          <span className="text">Content Type</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="menu-item">
                  <NavLink
                    className="menu-link d-flex align-items-center"
                    to="/products"
                    data-bs-target="#submenu2"
                    data-bs-toggle="collapse"
                  >
                    <i className="icon fas fa-shopping-bag"></i>
                    <span className="text">Movies</span>
                  </NavLink>
                  <ul id="submenu2" className="collapse">
                    <li className="menu-item">
                      <NavLink
                        className="menu-link d-flex align-items-center"
                        to="/products"
                      >
                        <span className="text">Movie List</span>
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        className="menu-link d-flex align-items-center"
                        to="/addMovie"
                      >
                        <span className="text">Add Movie</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="menu-item">
                  <NavLink
                    className="menu-link d-flex align-items-center collapsed"
                    to="/addSeries"
                    data-bs-target="#submenu3"
                    data-bs-toggle="collapse"
                  >
                    <i className="icon fas fa-shopping-bag"></i>
                    <span className="text">Series</span>
                  </NavLink>
                  <ul id="submenu3" className="collapse">
                    <li className="menu-item">
                      <NavLink
                        className="menu-link d-flex align-items-center"
                        to="/series"
                      >
                        <span className="text">Series List</span>
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        className="menu-link d-flex align-items-center"
                        to="/addSeries"
                      >
                        <span className="text">Add Series</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className="menu-item">
                  <NavLink
                    className="menu-link d-flex align-items-center"
                    to="/interactive"
                  >
                    <i className="icon fab fa-artstation"></i>
                    <span className="text">Interactive</span>
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink
                    className="menu-link d-flex align-items-center"
                    to="/category"
                  >
                    <i className="icon fas fa-list"></i>
                    <span className="text">Categories</span>
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link d-flex align-items-center"
                    to="/orders"
                  >
                    <i className="icon fas fa-bags-shopping"></i>
                    <span className="text">Orders</span>
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link d-flex align-items-center"
                    to="/users"
                  >
                    <i className="icon fas fa-user"></i>
                    <span className="text">Users</span>
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link d-flex align-items-center"
                    to="/subscription"
                  >
                    <i className="icon fas fa-usd-circle"></i>
                    <span className="text">Subscription</span>
                  </NavLink>
                </li>
                
                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link d-flex align-items-center"
                    to="/partnerList"
                  >
                    <i className="icon fas fa-users"></i>
                    <span className="text">Partner List</span>
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link d-flex align-items-center"
                    to="/partnerPayment"
                  >
                    <i className="icon fas fa-money-bill"></i>
                    <span className="text">Partner Payment</span>
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link d-flex align-items-center"
                    to="/contact"
                  >
                    <i className="icon fab fa-wpforms"></i>
                    <span className="text">Contact</span>
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link d-flex align-items-center"
                    to="/giftcards"
                  >
                    <i className="icon fas fa-gift"></i>
                    <span className="text">Giftcards</span>
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link d-flex align-items-center"
                    to="/payments"
                  >
                    <i className="icon fas fa-money-check-alt"></i>
                    <span className="text">Payments</span>
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link d-flex align-items-center"
                    to="/odero/subscription"
                  >
                    <i className="icon fas fa-money-check-alt"></i>
                    <span className="text">Subscribers</span>
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link d-flex align-items-center"
                    to="/odero/search"
                  >
                    <i className="icon fas fa-money-check-alt"></i>
                    <span className="text">Odero Payments</span>
                  </NavLink>
                </li>

              </>
              : null}
            {/* <li className="menu-item">
              <NavLink
                className="menu-link d-flex align-items-center disabled"
                to="/sellers"
              >
                <i className="icon fas fa-store-alt"></i>
                <span className="text">Sellers</span>
              </NavLink>
            </li> */}
            {(userInfo && userInfo.user.claims.includes("Partner")) ?
              <>
                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link d-flex align-items-center"
                    to="/"
                    exact={true}
                  >
                    <i className="icon fas fa-home"></i>
                    <span className="text">Dashboard</span>
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link d-flex align-items-center"
                    to="/partnerStatistics"
                  >
                    <i className="icon fas fa-film"></i>
                    <span className="text">Partner Statistics</span>
                  </NavLink>
                </li>
              </>
              : null}
          </ul>
          <br />
          <br />
        </nav>
      </aside>
    </div>
  );
};

export default Sidebar;
