import Sidebar from "../components/sidebar";
import Header from "../components/Header";
import PartnerList from "../components/PartnerPayment/index";

const PartnerListScreen = () => {
    return (
        <>
            <Sidebar />
            <main className="main-wrap">
                <Header />
                <PartnerList />
            </main>
        </>
    );
}

export default PartnerListScreen