import * as React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Toast from "../LoadingError/Toast";
import { toast } from "react-toastify";
import {
   IInteractiveScenario,
   IQuestionFormat,
   IScenarioFormat,
   IScenarioPayloadFormat,
} from "./interactiveTypes";
import produce from 'immer';
import { addScenario, getInteractiveScenario, updateInteractiveScenario } from "../../api/metaflixApi";



export interface InteractiveScenarioFormProps { }

const ToastObjects = {
   pauseOnFocusLoss: false,
   draggable: false,
   pauseOnHover: false,
   autoClose: 2000,
 };

export default function InteractiveScenarioForm(
   props: InteractiveScenarioFormProps
) {
   const [key, setKey] = React.useState<string>("");
   const [videoChunk, setVideoChunk] = React.useState<string>("");
   const [isDirect, setIsDirect] = React.useState<boolean>(false);
   const [nextKey, setNextKey] = React.useState<string>('');
   const [question, setQuestion] = React.useState<IQuestionFormat>({ questionContent: "", options: [{text: '', nextScenario: '', default: true}] });

   const { filmId, scenarioId } = useParams()
   const history = useHistory()


   React.useEffect(() => {
      if (scenarioId !== 'add') {
         getInteractiveScenario(scenarioId).then( (res: {data: {data: IInteractiveScenario}}) => {
            const scenarioData = res.data.data;
            setKey(scenarioData.name)
            setVideoChunk(scenarioData.scenario.chunks.join('\n'))
            if (scenarioData.scenario.directNextScenario) {
               setIsDirect(true)
               setNextKey(scenarioData.scenario.directNextScenario)
            } else {
               setQuestion(scenarioData.scenario.question!)
            }
         })
      }
   }, [filmId, scenarioId])

   const addScenarioHandler = React.useCallback((data) => {
      addScenario(filmId, data).then(res => {
         history.push(`/interactive/${filmId}/scenarios`)
      })
   }, [filmId, history])

   const updateScenarioHandler = React.useCallback((data) => {
      data = {...data, contentId: scenarioId}
      updateInteractiveScenario(scenarioId, data).then(res => {
         toast.success("Scenario updated successfully", ToastObjects);
      })
   }, [scenarioId])

   const submitHandler = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const chunks = videoChunk.split('\n')
      const data: IScenarioFormat = { chunks }
      if (isDirect)
         data.directNextScenario = nextKey
      else
         data.question = question
      const payload: IScenarioPayloadFormat = {name: key, scenario: data}

      if (scenarioId === 'add') {
         addScenarioHandler(payload)
      } else {
         updateScenarioHandler(payload)
      }
   }, [isDirect, nextKey, question, scenarioId, videoChunk, addScenarioHandler, key, updateScenarioHandler]);

   const questionTitleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      setQuestion(produce((draft) => {
         draft.questionContent = e.target.value;
      }));
   }, [])

   const optionTextChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      setQuestion(produce((draft) => {
         draft.options![index].text = e.target.value;
      }));
   }, [])

   const optionScenarioKeyChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      setQuestion(produce((draft) => {
         draft.options![index].nextScenario = e.target.value;
      }));
   }, [])

   const optionIsDefaultChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      setQuestion(produce((draft) => {
         draft.options!.map((option, optionIndex) => option.default = index === optionIndex);
      }));
   }, [])

   const optionDeleteHandler = React.useCallback((index: number) => {
      setQuestion(produce((draft) => {
         draft.options!.splice(index, 1);
      }));
   }, [])

   const addOptionHandler = React.useCallback(() => {
      setQuestion(produce((draft) => {
         draft.options!.push({ text: "", nextScenario: "", default: false });
      }));
   }, [])


   // http://localhost:8080/interactive/649ed2920f98a10a0773bb7c/scenarios

   return (
      <div>
         <>
            <Toast />
            <section className="content-main" style={{ maxWidth: "1200px" }}>
               <form onSubmit={submitHandler}>
                  <div className="content-header">
                     <Link to={`/interactive/${filmId}/scenarios`} className="btn btn-danger text-white">
                        Go to Scenarios
                     </Link>
                     <h2 className="content-title">Update Series</h2>
                     <div>
                        <button type="submit" className="btn btn-primary">
                           Publish now
                        </button>
                     </div>
                  </div>

                  <div className="row mb-4">
                     <div className="col-xl-8 col-lg-8">
                        <div className="card mb-4 shadow-sm">
                           <div className="card-body">
                              <div className="mb-3">
                                 <label
                                    htmlFor="scenario-key"
                                    className="form-label"
                                 >
                                    Scenario Key
                                 </label>
                                 <input
                                    value={key}
                                    onChange={e => setKey(e.target.value)}
                                    type="text"
                                    className="form-control"
                                    id="scenario-key"
                                    placeholder="Scenario Key"
                                 />
                              </div>
                              <div className="mb-3">
                                 <label
                                    htmlFor="videoChunk"
                                    className="form-label"
                                 >
                                    Video URL
                                 </label>
                                    <textarea
                                       value={videoChunk}
                                       onChange={e => setVideoChunk(e.target.value)}
                                       className="form-control"
                                       id="videoChunk"
                                       placeholder={'video/urls/1\nvideo/urls/2\nvideo/urls/3\n...'}
                                       rows={6}
                                    />
                              </div>
                              <div className="mb-3">
                                 <label
                                    htmlFor="isDirect"
                                    className="form-check-label me-2"
                                 >
                                    Is Direct
                                 </label>
                                 {/* fill value and onChange */}
                                 <input
                                    onChange={e => setIsDirect(e.target.checked)}
                                    checked={isDirect}
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={isDirect}
                                    id="isDirect"
                                    placeholder="Is Direct"
                                 />
                              </div>
                              {
                                 isDirect
                                    ?
                                    (
                                       <div className="mb-3">
                                          <label
                                             htmlFor="next-scenario-key"
                                             className="form-label"
                                          >
                                             Next Scenario Key
                                          </label>
                                          <input
                                             value={nextKey}
                                             onChange={e => setNextKey(e.target.value)}
                                             type="text"
                                             className="form-control"
                                             id="next-scenario-key"
                                             placeholder="Scenario Key"
                                          />
                                       </div>
                                    )
                                    :
                                    (
                                       <>
                                          <div className="mb-3">
                                             <label
                                                htmlFor="Question Title"
                                                className="form-label"
                                             >
                                                Question Title
                                             </label>
                                             <input
                                                value={question.questionContent}
                                                onChange={questionTitleChange}
                                                type="text"
                                                className="form-control"
                                                id="Question Title"
                                                placeholder="Question Title"
                                             />
                                          </div>
                                          <p>Options:</p>
                                          <div className="mb-3">
                                             <div className="mp-3 row align-items-center">
                                                {question.options?.map((option, index) => (
                                                   <div className="row mb-3 align-items-center" key={index}>
                                                      <div className="col-1">#{index + 1}</div>
                                                      <div className="col-5">
                                                         <input
                                                            value={option.text}
                                                            onChange={e => optionTextChange(e, index)}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Option Text"
                                                         />
                                                      </div>
                                                      <div className="col-3">
                                                         <input
                                                            value={option.nextScenario}
                                                            onChange={e => optionScenarioKeyChange(e, index)}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Scenario Key"
                                                         />
                                                      </div>
                                                      <div className="col-2">
                                                         <label htmlFor="isDefault" className="form-check-label me-2">Default</label>
                                                         <input
                                                            onChange={e => optionIsDefaultChange(e, index)}
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            checked={option.default}
                                                            id="isDefault"
                                                         />
                                                      </div>
                                                      <div className="col-1">
                                                         <button type="button" onClick={() => optionDeleteHandler(index)} className="btn btn-danger"><i className="fas fa-trash" /></button>
                                                      </div>
                                                   </div>
                                                ))}
                                             </div>
                                          </div>
                                          <button type="button" className="btn btn-success" onClick={addOptionHandler}>Add Option</button>
                                       </>
                                    )
                              }
                           </div>
                        </div>
                     </div>
                  </div>
               </form>
            </section>
         </>
      </div>
   );
}
