// import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { deletePlatformRef } from "../../Redux/Actions/PlatformRefActions";
// import { PLATFORM_LIST_REQUEST } from "../../Redux/Constants/PlatformConstants";
const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};
const PlatformRefsTable = ({ platformRefs }) => {
  // const [platformRefList,setPlatformRefList]=useState([])
  const dispatch = useDispatch();
  const deletehandler = (id) => { 
    if (window.confirm("Are you sure??")) {
      toast.success("Platform Removed", ToastObjects);
      dispatch(deletePlatformRef(id));
      // dispatch({ type: PLATFORM_LIST_REQUEST });
    }
  };

  // useEffect(()=>{
  //   setPlatformRefList(platformRefs)
  // },[platformRefs])

  return (
    <div className="col-md-12 col-lg-8">
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Platform</th>
            <th>Catalog</th>
            <th>Type</th>
            <th className="text-end">Action</th>
          </tr>
        </thead>
        {/* Table Data */}
        <tbody>
          {platformRefs.map((platform) => (
            <tr key={platform.id}>
              <td>{platform.row_number}</td>
              <td>
                <b>{platform.platform}</b>
              </td>

              <td>
                <b>{platform.catalog_name}</b>
              </td>
              <td>
                <b>{platform.type_name}</b>
              </td>

              <td className="text-end">
                <div className="dropdown">
                  <Link
                    to="#"
                    data-bs-toggle="dropdown"
                    className="btn btn-light"
                  >
                    <i className="fas fa-ellipsis-h"></i>
                  </Link>
                  <div className="dropdown-menu">
                    <Link
                      className="dropdown-item"
                      to={`/platform-ref/${platform.id}/edit`}
                    >
                      Edit info
                    </Link>
                    <Link
                      className="dropdown-item text-danger"
                      to="#"
                      onClick={() => deletehandler(platform.id)}
                    >
                      Delete
                    </Link>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlatformRefsTable;
