import React, { useEffect, useState } from "react";
import CreateCategory from "./CreateCategory";
import CategoriesTable from "./CategoriesTable";
import { useDispatch, useSelector } from "react-redux";
import { editCategory, listCategories } from "../../Redux/Actions/CategoryActions";
import ReactPaginate from "react-paginate";

const MainCategories = (props) => {
  const dispatch = useDispatch();
  const categoryId=props.categoryId??null;
  const categoryList = useSelector((state) => state.categoryList);
  const {  categories } = categoryList;
  const[page,setPage]=useState(1);
  const categoryEdit = useSelector((state) => state.categoryEdit);
  const {  category } = categoryEdit;
  useEffect(() => {
    if (categoryId !== null) {
      dispatch(editCategory(categoryId));
    }
  }, [categoryId, dispatch]);
  useEffect(() => {
    dispatch(listCategories({lang:"AZ",page,q:""}));
  }, [dispatch,page]);
  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };
  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Categories</h2>
      </div>

      <div className="card shadow-sm">
        <div className="card-body">
          <div className="row">
            {/* Create category */}
            <CreateCategory category={category}/>
            {/* Categories table */}
            <CategoriesTable  categories={categories.data? categories.data:[]}/>
          </div>
          <div className="d-flex justify-content-end">
          <ReactPaginate
              breakLabel="..."
              nextLabel="next>"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={categories ? Math.ceil( categories.totalPage / 15):1}
              previousLabel="< previous"
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
              renderOnZeroPageCount={1}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainCategories;
