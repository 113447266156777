import React, { useRef } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../api/baseConfig";
import { useHistory } from "react-router-dom";
import classes from "./Subscription.module.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { toast } from "react-toastify";
import Toast from "../LoadingError/Toast";

const initialState = {
   month: 1,
   price: 6,
   discount: 3,
};
const loadSubDetailsAction = (state, action) => {
   return action.data;
};
const editMonthAction = (state, action) => {
   return { ...state, month: action.month };
};
const editPriceAction = (state, action) => {
   return { ...state, price: action.price };
};
const editDiscountAction = (state, action) => {
   return { ...state, discount: action.discount };
};
const editNameAction = (state, action) => {
   const newLanguage = [...state.language];
   const langIndex = newLanguage.findIndex(
      (e) => e.lang_code === action.lang_code
   );
   const langInfo = newLanguage[langIndex];
   const newLangInfo = { ...langInfo, [action.key]: action.value };
   newLanguage[langIndex] = newLangInfo;

   return { ...state, language: newLanguage };
};

const reducer = (state, action) => {
   switch (action.type) {
      case "LOAD_SUB_INFO":
         return loadSubDetailsAction(state, action);
      case "EDIT_MONTH":
         return editMonthAction(state, action);
      case "EDIT_PRICE":
         return editPriceAction(state, action);
      case "EDIT_DISCOUNT":
         return editDiscountAction(state, action);
      case "EDIT_NAME":
         return editNameAction(state, action);
      default:
   }
};

const SubsciptionDetail = (props) => {
   const [state, dispatch] = React.useReducer(reducer, initialState);

   const formRef = useRef(null)

   useEffect(() => {});

   let history = useHistory();

   const params = useParams();
   const subId = params.id;

   useEffect(() => {
      const token = JSON.parse(localStorage.getItem("userInfo")).token;
      axios
         .get(BASE_URL + "/api/package/getbyid?id=" + subId, {
            headers: { "x-access-token": token },
         })
         .then((response) => {
            dispatch({
               type: "LOAD_SUB_INFO",
               data: response.data,
            });
         });
   }, [subId]);

   const monthChangeHandler = React.useCallback((e) => {
      dispatch({
         type: "EDIT_MONTH",
         month: parseFloat(e.target.value),
      });
   }, []);
   const priceChangeHandler = React.useCallback((e) => {
      dispatch({
         type: "EDIT_PRICE",
         price: parseFloat(e.target.value),
      });
   }, []);
   const discountChangeHandler = React.useCallback((e) => {
      dispatch({
         type: "EDIT_DISCOUNT",
         discount: parseFloat(e.target.value),
      });
   }, []);
   const nameChangeHandler = React.useCallback((key, value, lang_code) => {
      dispatch({
         type: "EDIT_NAME",
         value: value,
         key: key,
         lang_code,
      });
   }, []);

   const submitHandler = React.useCallback((e) => {
      e.preventDefault()
      const token = JSON.parse(localStorage.getItem("userInfo")).token;
      axios
         .put(
            BASE_URL + "/api/package/update",
            {
               language: state.language,
               id: state.id,
               month: state.month,
               price: state.price,
               discount: state.discount,
            },
            {
               headers: { "x-access-token": token },
            }
         )
         .then((response) => {
            if (response.data?.status === 200) {
               toast.success("Package updated");
            }
            else {
               toast.error("Package not updated");
            }
         })

   }, [state]);

   // const saveButtonHandler = React.useCallback((e) => {
   //    e.preventDefault()
   //    formRef.current.submit()
   // }, [])

   return (
      <section className="content-main" style={{ maxWidth: "1200px" }}>
         <Toast />
         <div className="content-header">
            <button
               onClick={() => {
                  history.goBack();
               }}
               className="btn btn-danger text-white"
            >
               Go Back
            </button>
            <h2 className="content-title">Subscription Plan</h2>
            <div>
            </div>
         </div>
         <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
               <form className="card mb-4 shadow-sm" onSubmit={submitHandler} ref={formRef}>
                  {state ? (
                     <>
                        <div className="card-body">
                           <h5>Month</h5>
                           <input
                              type="number"
                              onChange={monthChangeHandler}
                              value={state.month}
                              className={classes.inputField + " form-control"}
                           />{" "}
                           <h6 className="d-inline">month(s)</h6>
                        </div>
                        <div className="card-body">
                           <h5>Price</h5>
                           <input
                              type="number"
                              onChange={priceChangeHandler}
                              required
                              value={state.price}
                              className={classes.inputField + " form-control"}
                           />{" "}
                           <h6 className="d-inline">₼</h6>
                        </div>
                        <div className="card-body">
                           <h5>Discount</h5>
                           <input
                              type="number"
                              onChange={discountChangeHandler}
                              value={state.discount}
                              className={classes.inputField + " form-control"}
                           />
                        </div>
                     </>
                  ) : null}
                  <div className={classes.tabDiv}>
                     <Tabs>
                        {state &&
                           state.language &&
                           state.language.map((l, index) => (
                              <Tab
                                 eventKey={l.lang_code}
                                 key={l.lang_code}
                                 title={l.lang_code}
                              >
                                 <div className="mb-4">
                                    <label className="form-label">Title</label>
                                    <input
                                       type="text"
                                       placeholder="Type here"
                                       className="form-control"
                                       name="name"
                                       required
                                       value={l.name}
                                       onChange={(e) =>
                                          nameChangeHandler(
                                             "name",
                                             e.target.value,
                                             l.lang_code
                                          )
                                       }
                                    />
                                 </div>

                                 <div className="mb-4">
                                    <label className="form-label">
                                       Description
                                    </label>
                                    <textarea
                                       placeholder="Type here"
                                       className="form-control"
                                       name={`description`}
                                       rows="7"
                                       value={l.description}
                                       onChange={(e) =>
                                          nameChangeHandler(
                                             "description",
                                             e.target.value,
                                             l.lang_code
                                          )
                                       }
                                    ></textarea>
                                 </div>
                              </Tab>
                           ))}
                     </Tabs>
                  </div>
                  <button className="btn btn-primary" type="submit">
                    Save
                  </button>
               </form>
            </div>
         </div>
      </section>
   );
};

export default SubsciptionDetail;
