import MainInteractive from '../components/Interactive/MainInteractive';
import * as React from 'react';
import Header from "../components/Header";
import Sidebar from "./../components/sidebar";

export interface IInteractiveScreenProps {
}

export default function InteractiveScreen (props: IInteractiveScreenProps) {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <MainInteractive />
      </main>
    </>
  );
}
