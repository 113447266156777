import Sidebar from "../components/sidebar";
import Header from "../components/Header";
import { PartnerListMain } from "../components/PartnerList";

const PartnerListScreen = () => {
    return (
        <>
            <Sidebar />
            <main className="main-wrap">
                <Header />
                <PartnerListMain />
            </main>
        </>
    );
}

export default PartnerListScreen