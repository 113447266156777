import { Link } from "react-router-dom";
import classes from "../Directors/Director.module.css";


const ActorsTable = ({ actors, onDelete, onEdit }) => {
  return (
    <div className="col-md-12 col-lg-8">
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th className="text-end">Action</th>
          </tr>
        </thead>
        {/* Table Data */}
        <tbody>
          {actors.map((actor) => (
            <tr key={actor.id}>
              <td>
                <b>{actor.name}</b>
              </td>

              <td className="text-end">
                <div className="dropdown">
                  <Link
                    to="#"
                    data-bs-toggle="dropdown"
                    className="btn btn-light"
                  >
                    <i className="fas fa-ellipsis-h"></i>
                  </Link>
                  <div className="dropdown-menu">
                    <div
                      className={classes.buttons + " dropdown-item"}
                      onClick={() => onEdit(actor?.id)}
                    >
                      Edit info
                    </div>
                    <div className={classes.buttons + " dropdown-item text-danger"} onClick={()=>{onDelete(actor.id)}}>
                      Delete
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ActorsTable;
