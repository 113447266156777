import React from 'react'
import ContactList from '../Contact/ContactList'

function ContactListMain() {
  return (
    <section className="content-main">
            <div className="content-header">
                <h2 className="content-title">Contact List</h2>
            </div>

            <div className="card mb-4 shadow-sm">
                <header className="card-header bg-white">
                    <div className="row gx-3 py-3">
                        <div className="col-lg-2 col-6 col-md-3">
                        </div>
                        
                    </div>
                </header>
                <div className="card-body">
                    <div className="table-responsive">
                        <ContactList />
                    </div>
                </div>
            </div>
        </section>
  )
}

export default ContactListMain