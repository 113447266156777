
export const partnerListReducer = (state = { partners: [] }, action) => {
    switch (action.type) {
        case 'GET_ALL_PARTNERS':
            return { ...state, partners: action.payload }
            case 'GET_ALL_PAYMENTS':
              return { ...state, loading:true }
            case 'GET_ALL_PAYMENTS_SUCCESS':
              return { ...state, loading:false, data: action.payload,sum:action.sum,total:action.total }
              case 'GET_ALL_PAYMENTS_ERROR':
                return { ...state ,loading:false, error: action.payload }
        case 'DELETE_PARTNER':
            return { ...state, partners: state.partners.filter(partner => partner.user_id !== action.payload) }
        default:
            return state
    }
}

export const payToPartnerReducer=(state = {}, action)=>{
    switch (action.type) {
        case 'PARTNER_PAY_REQUEST':
          return { loading: true };
        case 'PARTNER_PAY_SUCCESS':
          return { loading: false, success: true,message:action.payload};
        case 'PARTNER_PAY_FAIL':
          return { loading: false, error: action.payload };
        default:
          return state;
      }
}