import React from 'react'
import Sidebar from "../components/sidebar";
import Header from '../components/Header';
import SubscriptionDetail from '../components/Subscription/SubscriptionDetail';

function SubscriptionDetailScreen() {
  return (
    <>
        <Sidebar />
        <main className="main-wrap">
            <Header />
            <SubscriptionDetail />
        </main>

    </>
  )
}

export default SubscriptionDetailScreen