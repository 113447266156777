import React from "react";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../api/baseConfig";

function PaymentsList(props) {
  const [page, setPage] = useState(1);
  const [paymentsInfo, setPaymentsInfo] = useState({});

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const loadPayments = React.useCallback((page) => {
    axios
      .get(`${BASE_URL}/api/payment/subscriberpayment?take=20&page=`+page)
      .then((res) => {
        setPaymentsInfo(res.data);
      });
  }, []);

  const loadFilteredPayments = React.useCallback((page) => {
    axios
      .get(`${BASE_URL}/api/payment/subscriberpayment?page=${page}&take=20&package_id=${props.filterInfo.package_id}&promo_code=${props.filterInfo.promo_code}&email=${props.filterInfo.email}`)
      .then((res) => {
        setPaymentsInfo(res.data);
      });
  }, [props.filterInfo]);


  React.useEffect(() => {
    if(props.isFiltered){
      loadFilteredPayments(page);
    }else{
      loadPayments(page);
    }
  }, [loadPayments, loadFilteredPayments, props, page]);


  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Email</th>
            <th scope="col">Discount</th>
            <th scope="col">Promo-Code</th>
            <th scope="col">Package name</th>
            <th scope="col">Price</th>
            <th scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
            {paymentsInfo.data &&
            paymentsInfo.data.map((payment) => (
                <tr key={payment.id}>
                    <td>{payment.email}</td>
                    <td>{payment.promo_discount}</td>
                    <td>{payment.promo_code}</td>
                    <td>{payment.package_name}</td>
                    <td>{payment.paid_price}</td>
                    <td>{payment.payment_date}</td>
                </tr>
            ))}
        </tbody>
      </table>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next>"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={
          paymentsInfo.total !== 0 ? Math.ceil(paymentsInfo.total / 20) : 1
        }
        previousLabel="< previous"
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
        renderOnZeroPageCount={1}
        forcePage={page - 1}
      />
    </>
  );
}

export default PaymentsList;
