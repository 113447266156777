export const PLATFORM_LIST_REQUEST = "PLATFORM_LIST_REQUEST";
export const PLATFORM_LIST_SUCCESS = "PLATFORM_LIST_SUCCESS";
export const PLATFORM_LIST_FAIL = "PLATFORM_LIST_FAIL";

export const PLATFORM_EDIT_REQUEST = "PLATFORM_EDIT_REQUEST";
export const PLATFORM_EDIT_SUCCESS = "PLATFORM_EDIT_SUCCESS";
export const PLATFORM_EDIT_FAIL = "PLATFORM_EDIT_FAIL";

export const PLATFORM_DELETE_REQUEST = "PLATFORM_DELETE_REQUEST";
export const PLATFORM_DELETE_SUCCESS = "PLATFORM_DELETE_SUCCESS";
export const PLATFORM_DELETE_FAIL = "PLATFORM_DELETE_FAIL";

export const PLATFORM_CREATE_REQUEST = "PLATFORM_CREATE_REQUEST";
export const PLATFORM_CREATE_SUCCESS = "PLATFORM_CREATE_SUCCESS";
export const PLATFORM_CREATE_FAIL = "PLATFORM_CREATE_FAIL";
export const PLATFORM_CREATE_RESET = "PLATFORM_CREATE_RESET";

export const PLATFORM_UPDATE_REQUEST = "PLATFORM_UPDATE_REQUEST";
export const PLATFORM_UPDATE_SUCCESS = "PLATFORM_UPDATE_SUCCESS";
export const PLATFORM_UPDATE_FAIL = "PLATFORM_UPDATE_FAIL";
export const PLATFORM_UPDATE_RESET = "PLATFORM_UPDATE_RESET";
