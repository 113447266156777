export const CONTENT_TYPE_LIST_REQUEST="CONTENT_TYPE_LIST_REQUEST";
export const CONTENT_TYPE_LIST_SUCCESS="CONTENT_TYPE_LIST_SUCCESS";
export const CONTENT_TYPE_LIST_FAIL="CONTENT_TYPE_LIST_FAIL";

export const CONTENT_TYPE_EDIT_REQUEST = "CONTENT_TYPE_EDIT_REQUEST";
export const CONTENT_TYPE_EDIT_SUCCESS = "CONTENT_TYPE_EDIT_SUCCESS";
export const CONTENT_TYPE_EDIT_FAIL = "CONTENT_TYPE_EDIT_FAIL";

export const CONTENT_TYPE_DELETE_REQUEST = "CONTENT_TYPE_DELETE_REQUEST";
export const CONTENT_TYPE_DELETE_SUCCESS = "CONTENT_TYPE_DELETE_SUCCESS";
export const CONTENT_TYPE_DELETE_FAIL = "CONTENT_TYPE_DELETE_FAIL";

export const CONTENT_TYPE_CREATE_REQUEST = "CONTENT_TYPE_CREATE_REQUEST";
export const CONTENT_TYPE_CREATE_SUCCESS = "CONTENT_TYPE_CREATE_SUCCESS";
export const CONTENT_TYPE_CREATE_FAIL = "CONTENT_TYPE_CREATE_FAIL";
export const CONTENT_TYPE_CREATE_RESET = "CONTENT_TYPE_CREATE_RESET";

export const CONTENT_TYPE_UPDATE_REQUEST = "CONTENT_TYPE_UPDATE_REQUEST";
export const CONTENT_TYPE_UPDATE_SUCCESS = "CONTENT_TYPE_UPDATE_SUCCESS";
export const CONTENT_TYPE_UPDATE_FAIL = "CONTENT_TYPE_UPDATE_FAIL";
export const CONTENT_TYPE_UPDATE_RESET = "CONTENT_TYPE_UPDATE_RESET";
