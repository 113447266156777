export const EPISODE_LIST_REQUEST = "EPISODE_LIST_REQUEST";
export const EPISODE_LIST_SUCCESS = "EPISODE_LIST_SUCCESS";
export const EPISODE_LIST_FAIL = "EPISODE_LIST_FAIL";

export const EPISODE_EDIT_REQUEST = "EPISODE_EDIT_REQUEST";
export const EPISODE_EDIT_SUCCESS = "EPISODE_EDIT_SUCCESS";
export const EPISODE_EDIT_FAIL = "EPISODE_EDIT_FAIL";

export const EPISODE_DELETE_REQUEST = "EPISODE_DELETE_REQUEST";
export const EPISODE_DELETE_SUCCESS = "EPISODE_DELETE_SUCCESS";
export const EPISODE_DELETE_FAIL = "EPISODE_DELETE_FAIL";

export const EPISODE_CREATE_REQUEST = "EPISODE_CREATE_REQUEST";
export const EPISODE_CREATE_SUCCESS = "EPISODE_CREATE_SUCCESS";
export const EPISODE_CREATE_FAIL = "EPISODE_CREATE_FAIL";
export const EPISODE_CREATE_RESET = "EPISODE_CREATE_RESET";

export const EPISODE_UPDATE_REQUEST = "EPISODE_UPDATE_REQUEST";
export const EPISODE_UPDATE_SUCCESS = "EPISODE_UPDATE_SUCCESS";
export const EPISODE_UPDATE_FAIL = "EPISODE_UPDATE_FAIL";
export const EPISODE_UPDATE_RESET = "EPISODE_UPDATE_RESET";

