import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../api/baseConfig";
import ReactPaginate from "react-paginate";
import classes from './Subscription.module.css'

const SubscriptionList = () => {
  const [subInfo, setSubInfo] = useState({});
  const [page, setPage] = useState(1);

  const loadSubInfo = React.useCallback(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    axios
      .get(BASE_URL + "/api/package/getall/EN", {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        setSubInfo(response.data);
      });
  }, []);

  useEffect(() => {
    loadSubInfo();
  }, [loadSubInfo]);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };



  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Plan</th>
            <th scope="col">Months</th>
            <th scope="col">Price</th>
            <th scope="col">Details</th>
          </tr>
        </thead>
        <tbody>
          {subInfo?.data?.length
            ? subInfo.data.map((sub) => (
                <tr key={sub.id}>
                  <td>{sub.name}</td>
                  <td>
                    <div
                      className={classes.monthBox+' bg-primary'} 
                    >
                      {sub.month} month(s)
                    </div>
                  </td>
                  <td>
                    <div
                      className={classes.priceBox+' bg-success'} 
                    >
                      {sub.price} ₼
                    </div>
                  </td>
                  <td>
                    <Link
                      to={`/sub-detail/${sub.id}`}
                      className="btn btn-warning"
                    >
                      Details
                    </Link>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next>"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={
          subInfo.total !== 0 ? Math.ceil(subInfo.total / 20) : 1
        }
        previousLabel="< previous"
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
        renderOnZeroPageCount={1}
        forcePage={page - 1}
      />
    </>
  );
};

export default SubscriptionList;
