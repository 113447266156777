import * as React from 'react';
import {Link} from 'react-router-dom'
import {deleteInteractiveFilm, getInteractiveFilms} from '../../api/metaflixApi';
import {IMAGE_BUCKET_URL} from '../../api/baseConfig';

export default function MainInteractive(props) {
    const [interactiveFilms, setInteractiveFilms] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    
    React.useEffect(() => {
        getInteractiveFilms().then(res => {
            setTotal(res.data.total)
            setInteractiveFilms(res.data.data)
        })
    }, [])

    const deleteHandler = React.useCallback((id) => {
        const res = window.confirm('Are you sure?')
        if (res) {
            deleteInteractiveFilm(id).then(res => {
                setInteractiveFilms(prev => prev.filter(f => f.id !== id))
            })
        }
    }, [])

    return (
        <div>
            <section className="content-main">
                <div className="content-header">
                    <h2 className="content-title">Interactive Films</h2>
                    <div>
                        <Link to="/addMovie?interactive=true" className="btn btn-primary">
                            Create new
                        </Link>
                    </div>
                </div>
                <div className="card shadow-sm">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 bg-dark bg-dark text-white text-center p-2 h3">
                                Total {total} film
                            </div>
                        </div>
                        <div className="row">
                                {interactiveFilms.map((film, index) => {
                                    return (
                                        <div key={film.id} className="col-3">
                                            <InteractiveFilmReference index={index} film={film} onDelete={() => deleteHandler(film.id)} />
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}




function InteractiveFilmReference(props) {

    return (
        <div className="card card-product-grid shadow-sm">
            <Link className="img-wrap pt-2" to="#">
                <img src={IMAGE_BUCKET_URL + props.film.main_picture} alt="" />
            </Link>
            <div className="info-wrap">
                <Link to="#" className="title text-truncate">
                    {props.film.name}
                </Link>
                <div className="price mb-2">${props.film.price}</div>
                <div className="row justify-content-center">
                    <Link
                        to={`/product/${props.film.id}/edit?interactive=true`}
                        className="btn btn-sm btn-success p-2 mb-2 pb-3 col-md-6"
                    >
                        <i className="fas fa-pen"></i>
                    </Link>
                    <Link
                        to={`/interactive/${props.film.id}/scenarios`}
                        className="btn btn-sm btn-info mb-2 p-2 pb-3 col-md-6"
                    >
                        <i className="fas fa-icicles"></i>
                    </Link>
                    <div
                        onClick={props.onDelete}
                        className="btn btn-sm btn-danger p-2 pb-3 col-md-6"
                    >
                        <i className="fas fa-trash-alt"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}