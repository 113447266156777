import React from 'react'
import ReactPaginate from "react-paginate";
import { useState } from "react";
import axios from "axios";
import { BASE_URL } from '../../api/baseConfig';

function GiftcardsList(props) {
    const [page, setPage] = useState(1);
    const [giftcardsInfo, setGiftcardsInfo] = useState({});


    const handlePageClick = (event) => {
        setPage(event.selected + 1);
    };


    const loadPromocodes = React.useCallback((page) => {
        axios
            .get(`${BASE_URL}/api/promo/?take=20&page=` + page)
            .then((res) => {
                setGiftcardsInfo(res.data);
            });
    }, []);

    const filteredPromocodes = React.useCallback((page) => {
        axios
            .get(`${BASE_URL}/api/promo/?take=20&page=${page}&company=${props.filterInfo.company}&package_id=${props.filterInfo.package_id}&discount=${props.filterInfo.discount}&code=${props.filterInfo.code}`)
            .then((res) => {
                setGiftcardsInfo(res.data);
            });
    }, [props.filterInfo]);

    React.useEffect(() => {
      if(props.isFiltered){
        filteredPromocodes(page);
      }else{
        loadPromocodes(page);
      }
    }, [page, filteredPromocodes, loadPromocodes, props.isFiltered]);


    
      
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">PromoCode</th>
            <th scope="col">Package</th>
            <th scope="col">Discount</th>
            <th scope="col">For Use</th>
            <th scope="col">Use Count</th>
            <th scope="col">Company</th>
            <th scope="col">Created</th>
          </tr>
        </thead>
        <tbody>
            {giftcardsInfo.data &&
            giftcardsInfo.data.map((giftcard) => (
                <tr key={giftcard.id}>
                    <td>{giftcard.code}</td>
                    <td>{giftcard.package}</td>
                    <td>{giftcard.discount} ₼</td>
                    <td>{giftcard.for_use}</td>
                    <td>{giftcard.use_count}</td>
                    <td>{giftcard.company}</td>
                    <td>{giftcard.create_date.toString().substring(0,10)}</td>
                </tr>
            ))}
        </tbody>
      </table>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next>"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={
            giftcardsInfo.total !== 0 ? Math.ceil(giftcardsInfo.total / 20) : 1
        }
        previousLabel="< previous"
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
        renderOnZeroPageCount={1}
        forcePage={page - 1}
      />
    </>
  )
}

export default GiftcardsList